import ConversionResult from "./ConversionResult";
import { getCSVData } from "../utils/csv-reader";
import { BridgeCategoryOptions, BridgeOptions, BridgeSoftware } from "./types";
import { getRowCategory, removeAllCategories } from "./shared/categories";
class Bridge {
  name: BridgeSoftware | undefined;
  inputFiles: File[];
  options: BridgeOptions;
  software: BridgeSoftware;

  constructor(
    inputFiles: File[],
    software: BridgeSoftware,
    options: BridgeOptions
  ) {
    this.inputFiles = inputFiles;
    this.software = software;
    this.options = options;
  }

  csvHeaders(): string[] | null {
    throw new Error("Subclasses must implement the csvHeaders method");
  }

  async combineInputFiles(): Promise<string> {
    console.log("[Bridge] Note: Using default combineInputFiles strategy.");
    const fileContents = await Promise.all(
      this.inputFiles.map((file) => file.text())
    );

    const merged = fileContents.join("\n").trim();
    console.log("[Bridge] Merged input files:", merged);
    return merged;
  }

  async parseCSV() {
    const headers = this.csvHeaders() as string[];
    const combined = await this.combineInputFiles();
    return getCSVData<typeof headers>(combined, headers);
  }

  async transformCSV(_parsed: any): Promise<any> {
    throw new Error("Subclasses must implement the transformCSV method");
  }

  async handleCategories(
    parsed: string[][],
    categoryOptions: BridgeCategoryOptions | null
  ): Promise<string[][]> {
    console.log({ parsed });
    const handled = parsed
      .map((row) => {
        const rowCategory = getRowCategory(row);
        if (!rowCategory) {
          return row;
        }
        const categoryOption = rowCategory
          ? categoryOptions?.[rowCategory]
          : null;
        if (categoryOption && categoryOption.isEnabled) {
          return removeAllCategories(row);
        } else {
          console.log("[Bridge] Skipping row due to category option:", row);
          return null;
        }
      })
      .filter((row) => row);
    console.log({ handled });
    return handled as string[][];
  }

  async convert(): Promise<ConversionResult> {
    try {
      const parsed = await this.parseCSV();
      const result = await this.transformCSV(parsed);
      const filteredResult = await this.handleCategories(
        result,
        this.options.categoryOptions
      );
      const conversionResult = new ConversionResult(filteredResult);
      return conversionResult;
    } catch (error: any) {
      console.error("[Bridge] Conversion failed:", error);
      return new ConversionResult(null, error);
    }
  }
}

export default Bridge;
