const CATEGORY = "__CATEGORY";

export enum Categories {
  Wood = "Wood",
  HandlesAndKnobs = "Handles and Knobs",
  PolesAndCups = "Poles and Cups",
  Accessories = "Accessories",
  Hardware = "Hardware",
}

export type CategoryCell = {
  category: Categories;
  __identity: typeof CATEGORY;
};

export function makeCategory(category: Categories): CategoryCell {
  return { category, __identity: CATEGORY };
}

export function getRowCategory(row: Array<any>): Categories | undefined {
  const categoryCell = row.find((cell) => cell?.__identity === CATEGORY);
  console.log({ categoryCell });
  if (categoryCell) {
    console.log({ category: categoryCell.category });
    return categoryCell.category;
  }
}

export function removeAllCategories(row: Array<any>): Array<any> {
  return row.filter((cell) => !(cell?.__identity === CATEGORY));
}
