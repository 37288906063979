import Papa from "papaparse";

type OutputData = string[][] | null;

class ConversionResult {
  outputData: OutputData;
  err?: any;

  constructor(outputData: OutputData, error?: any) {
    this.outputData = outputData;
    this.err = error;
  }

  success() {
    return this.outputData && !this.error;
  }

  error() {
    return this.err;
  }

  data() {
    return this.outputData;
  }

  triggerDownload(filename: string) {
    const newcsv = Papa.unparse(this.outputData || []);
    const blob = new Blob([newcsv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const newFilename = filename.replace(/\u001D/g, "");
    link.setAttribute("href", url);
    link.setAttribute("download", newFilename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
  }
}

export default ConversionResult;
