import { Hardware, Hardwares } from "./types";

function deduplicateHardwareItems(hardwareRows: Hardwares): Hardwares {
  const hardwareMap: Map<string, Hardware> = new Map();
  hardwareRows.forEach((row) => {
    const hardwareRowWithoutQuantity = row.slice(0, 2).concat(row.slice(3));
    const key = hardwareRowWithoutQuantity.join("-");
    const existingRow = hardwareMap.get(key);
    if (existingRow) {
      const existingQuantity = existingRow[2] as string || "1";
      const newQuantity = row[2];
      const totalQuantity = parseFloat(existingQuantity) + parseFloat(newQuantity as string);
      existingRow[2] = totalQuantity.toString();
      hardwareMap.set(key, existingRow);
    } else {
      hardwareMap.set(key, row);
    }
  });
  // get just values from map
  const newHardwareRows = Array.from(hardwareMap.values());
  return newHardwareRows;
}

export default deduplicateHardwareItems;
