import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import OutputTable from "./OutputTable";
import SoftwareSelector from "./SoftwareSelector";
import BridgeFactory from "../bridge/BridgeFactory";
import ConversionResult from "../bridge/ConversionResult";
import { BridgeCategoryOptions, BridgeSoftware } from "../bridge/types";
import { isBeta, isProduction } from "../utils/environment";
import CategoryOptionSelect from "./CategoryOptionSelect";
//import { Categories } from "../bridge/shared/categories";

function App() {
  const [result, setResult] = useState<ConversionResult | null>(null);
  const [resultData, setResultData] = useState<
    ConversionResult["outputData"] | null
  >(null);
  const [software, setSoftware] = useState<BridgeSoftware | undefined>();
  const [outputFilename, setOutputFilename] = useState<string>("output.csv");

  const [categoryOptions, setCategoryOptions] =
    useState<BridgeCategoryOptions | null>(null);

  useEffect(() => {
    if (software) {
      setCategoryOptions(BridgeFactory.getBridgeCategoryOptions(software));
    }
    setResult(null);
    setResultData(null);
  }, [software]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!software) {
        alert("Please select a software first.");
        return;
      }
      const files = acceptedFiles;
      const mmddyyyy = new Date()
        .toLocaleDateString("en-US")
        .replace(/\//g, "-");

      const newFilename =
        files.length > 1
          ? `multifile-converted-${mmddyyyy}.csv`
          : files[0].name.replace(".csv", `-converted-${mmddyyyy}.csv`);
      setOutputFilename(newFilename);
      const bridge = BridgeFactory.createBridge(files, software, {
        categoryOptions,
      });
      if (!bridge) {
        alert(
          "Whoops! Something went wrong. It looks like that software is not supported."
        );
        throw new Error(`Unsupported software: ${software}`);
        return;
      }
      const conversion = await bridge.convert();

      setResult(conversion);
      setResultData(conversion.data());

      if (isProduction()) {
        conversion.triggerDownload(newFilename);
      }
    },
    [software, categoryOptions, outputFilename, setOutputFilename]
  );

  function downloadResult() {
    if (result) {
      result.triggerDownload(outputFilename);
    }
  }

  function resetResult() {
    setResult(null);
    setResultData(null);
  }

  useEffect(() => {
    console.log({ categoryOptions });
  }, [categoryOptions]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <section className="w-full px-6 mb-0 flex justify-between">
        <div>
          <p className="text-[20px] font-[600]">Choose your Design Software:</p>
          <SoftwareSelector onSelect={(value: any) => setSoftware(value)} />
        </div>

        <div className="text-right">
          <p className="text-[20px] font-[600]">Include:</p>
          {categoryOptions &&
            Object.entries(categoryOptions).map(([key, option]) => {
              return (
                <CategoryOptionSelect
                  key={key}
                  label={key}
                  checked={option.isEnabled}
                  disabled={!option.canChange}
                  onSelect={(checked: boolean) => {
                    if (categoryOptions) {
                      setCategoryOptions({
                        ...categoryOptions,
                        [key]: {
                          ...option,
                          isEnabled: checked,
                        },
                      });
                    }
                  }}
                />
              );
            })}
        </div>
      </section>

      <section className="w-full px-6 mt-3">
        <div
          {...getRootProps()}
          className="w-full h-[200px] flex items-center justify-center rounded-md bg-[#F8F8F8] cursor-pointer hover:bg-[#F2F2F2] transition-all flex-col border-2 border-[#ccc] border-dashed"
        >
          <input {...getInputProps()} />

          <img src="/upload-icon.svg" alt="Upload Icon" className="w-[80px]" />

          <div>
            {software === undefined ? <p>Please choose a software above first.</p> : null}
            {software !== undefined && (
              <>
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag and drop your CSV here, or{" "}
                    <span className="underline">click</span> to browse.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        {/* We only show buttons and preview table in beta/staging */}
        {isBeta() && result && (
          <div className="flex justify-between space-x-5">
            <button
              className="rounded-lg bg-slate-700 text-white px-5 py-4 mt-5 flex-1"
              onClick={downloadResult}
            >
              Download Result
            </button>

            <button
              className="rounded-lg bg-slate-500 text-white px-5 py-4 mt-5 flex-1"
              onClick={resetResult}
            >
              Reset
            </button>
          </div>
        )}
        {isBeta() && resultData && (
          <OutputTable data={resultData} className="mt-5" />
        )}
      </section>
    </>
  );
}

export default App;
