import Papa from "papaparse";

async function getCSVData<Headers extends string[]>(
  data: string,
  headers?: Headers
): Promise<Record<Headers[number], string>[]> {
  // const csvString = await convertCsvToString(file);
  const dataWithHeaders = headers
    ? headers.join(",") + "\n" + data
    : data;

  return new Promise((resolve, reject) => {
    Papa.parse<Record<Headers[number], string>>(dataWithHeaders, {
      header: true,
      transformHeader: (header: string, columnIndex: number): string => {
        if (headers) {
          return headers[columnIndex] || `column${columnIndex}`;
        }
        return header;
      },
      complete: function (results) {
        resolve(results.data);
      },
      error: function (error: Error) {
        reject(error);
      },
    });
  });
}

export { getCSVData };
