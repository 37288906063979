import PropTypes from "prop-types";

function CategoryOptionSelect({
  label,
  checked,
  disabled,
  extraClass = "",
  onSelect,
}: {
  label: string;
  checked: boolean;
  disabled: boolean;
  extraClass?: string;
  onSelect: (checked: boolean) => void;
}) {
  return (
    <label className={`block ${extraClass}`}>
      <input
        disabled={disabled}
        type="checkbox"
        className="h-5 w-5 text-blue-600"
        name={`category[${label}]`}
        checked={checked}
        onChange={(evt) => {
          console.log(evt.target.checked);
          onSelect(evt.target.checked);
        }}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
}

CategoryOptionSelect.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  extraClass: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default CategoryOptionSelect;
