import { Hardwares } from "./types";

function updateHardwareQuantities(hardwareRows: Hardwares): Hardwares {
  return hardwareRows.map((hardwareRow) => {
    const itemName = hardwareRow[1] as string;
    console.log(itemName);
    if (itemName.includes("Suspension Bracket")) {
      if (itemName.endsWith("Left")) {
        hardwareRow[2] = Math.ceil(parseFloat(hardwareRow[2] as string));
      }
      if (itemName.endsWith("Right")) {
        hardwareRow[2] = Math.floor(parseFloat(hardwareRow[2] as string));
      }
    }
    if (itemName.includes("Wall Rail & Wall Rail Cover")) {
      //This is code I added and need to check into @ToDo
      console.log("Total Length of wall rail", hardwareRow[2]);
      hardwareRow[2] = Math.ceil(parseFloat(hardwareRow[2] as string) / 93);
    }
    return hardwareRow;
  });
}

export default updateHardwareQuantities;
