import { getDrawerFaceInfo } from "../shared/helpers";
import { CPRow } from "./types";

function getAttributesFromCPSColumnValue(
  name: string
): Record<string, string> | undefined {
  const attributeMatchers = [
    /^(.*?)(?<handlesize>0|16|32|64|96|128|160|192|224|256)(.*?)/,
  ];

  const attributes = {}; // Initialize an empty object to store attributes

  for (const matcher of attributeMatchers) {
    const result = matcher.exec(name);
    if (result && result.groups) {
      // Merge captured groups into the attributes object
      Object.assign(attributes, result.groups);
    }
  }

  if (Object.keys(attributes).length > 0) {
    return attributes;
  } else {
    return undefined; // Return undefined if no attributes are found
  }
}

function getDoorOpeningStyle(DoorStyleCode: string): string {
  const map = new Map([
    ["L", "Single Left"],
    ["R", "Single Right"],
    ["T", "Single Top"],
    ["B", "Single Bottom"],
    ["BH", "Single Bottom"],
  ]);
  return map.get(DoorStyleCode) || DoorStyleCode;
}

function getDrawerWoodType(DoorStyleCode: string): string {
  const map = new Map([
    ["Melamine Box - Side Mount", "3/4 White"],
    ["Birch Veneer Box", "3/4 Veneer"],
    ["Endura Box - White", "5/8 White"],
    ["Endura Box - Grey", "5/8 Grey"],
    ["Melamine Box - Bottom Mount", "3/4 White"],
  ]);
  return map.get(DoorStyleCode) || DoorStyleCode;
}

function getDrawerEdgeTapeType(DoorStyleCode: string): string {
  const map = new Map([
    ["Melamine Box - Side Mount", "White Flat Tape"],
    ["Birch Veneer Box", "Veneer Flat Tape"],
    ["Endura Box - White", "White Flat Tape"],
    ["Endura Box - Grey", "Grey Flat Tape"],
    ["Melamine Box - Bottom Mount", "White Flat Tape"],
  ]);
  return map.get(DoorStyleCode) || DoorStyleCode;
}

function getSimpleSlideTypeName(PartName: string): string {
  const map = new Map([
    ["Bottom Mount", "Bottom Mount Slides"],
    ["Side Mount Reg - Hettich", "Side Mount Slides"],
    ["Side Mount SC - Repon", "Side Mount Soft Close Slides"],
  ]);
  return map.get(PartName) || PartName;
}

let suspensionBracketCoverCounter = 0;
function alternateSBCLeftRight() {
  const output =
    suspensionBracketCoverCounter % 2 === 0
      ? "Suspension Bracket Cover - Left"
      : "Suspension Bracket Cover - Right";
  suspensionBracketCoverCounter++;

  return output;
}

let suspensionBracketCounter = 0;
function alternateSBLeftRight() {
  const output =
    suspensionBracketCounter % 2 === 0
      ? "Suspension Bracket - Left"
      : "Suspension Bracket - Right";
  suspensionBracketCounter++;
  return output;
}

function getTapingInstructions(
  EBFront: string,
  EBBack: string,
  EBTop: string,
  EBBottom: string,
  Height: string,
  Width: string,
  Depth: string
) {
  const parsedHeight = parseFloat(Height);
  const parsedWidth = parseFloat(Width);
  const parsedDepth = parseFloat(Depth);

  // Swap function to exchange values
  // const swap = (a: string, b: string) => [b, a];
  if (
    (parsedWidth === 0 && parsedHeight < parsedDepth) ||
    (parsedHeight === 0 && parsedWidth < parsedDepth) ||
    (parsedDepth === 0 && parsedHeight < parsedWidth)
  ) {
    // Swap the values
    [EBFront, EBTop] = [EBTop, EBFront];
    [EBBack, EBBottom] = [EBBottom, EBBack];
  }
  // prettier-ignore
  if (EBFront === "N" && EBBack === "N" && EBTop === "N" && EBBottom === "N") {
    return "Board Only";
  } else if (EBFront === "Y" && EBBack === "N" && EBTop === "N" && EBBottom === "N") {
    return "1 - Length";
  } else if (EBFront === "Y" && EBBack === "Y" && EBTop === "N" && EBBottom === "N") {
    return "2 - Length";
  } else if (EBFront === "N" && EBBack === "N" && EBTop === "Y" && EBBottom === "N") {
    return "1 - Width";
  } else if (EBFront === "N" && EBBack === "N" && EBTop === "Y" && EBBottom === "Y") {
    return "2 - Width";
  } else if (EBFront === "Y" && EBBack === "N" && EBTop === "Y" && EBBottom === "N") {
    return "1 - Length 1 - Width";
  } else if (EBFront === "Y" && EBBack === "Y" && EBTop === "Y" && EBBottom === "N") {
    return "2 - Length 1 - Width";
  } else if (EBFront === "Y" && EBBack === "N" && EBTop === "Y" && EBBottom === "Y") {
    return "1 - Length 2 - Width";
  } else if (EBFront === "Y" && EBBack === "Y" && EBTop === "Y" && EBBottom === "Y") {
    return "All Sides";
  } else {
    return "Invalid combination";
  }
}

/**This is not completed, revisit to finalize 
function getDoorHandleLocation(row: {
  Height: number;
  Elevation: number;
  PartExport: string;
}): string {
  // Calculate derived variables
  const sizeOfHandleInches =
    parseFloat(row.PartExport.split("mm")[0].trim()) / 25.4;
  const topOfDoor = row.Height + row.Elevation;
  const bottomOfDoor = row.Elevation;
  const idealHandleCenter = 41.75;
  const idealHandleTopHole = 41.75 + sizeOfHandleInches / 2;
  const idealHandleBottomHole = 41.75 - sizeOfHandleInches / 2;
  const idealDoorTopHole = topOfDoor - 2.75;
  const idealDoorBottomHole = bottomOfDoor + 2.75;
  const idealDoorCenter = bottomOfDoor + row.Height / 2;

  // Calculate differences from the idealHandleOffFloor
  const diffTop = Math.abs(idealHandleCenter - idealDoorTopHole);
  const diffCenter = Math.abs(idealHandleCenter - idealDoorCenter);
  const diffBottom = Math.abs(idealHandleCenter - idealDoorBottomHole);

  // Find the closest match and return the corresponding location
  if (diffTop < diffCenter && diffTop < diffBottom) {
    return "Top";
  } else if (diffCenter < diffTop && diffCenter < diffBottom) {
    return "Center";
  } else {
    return "Bottom";
  }
}
*/


function getBoxType(row: CPRow, allRows: CPRow[]): string {
  const drawerBox = allRows.find(
    (searchRow) =>
      searchRow.ClosetName === row.ClosetName &&
      searchRow.WallNum === row.WallNum &&
      searchRow.SectionNum === row.SectionNum &&
      searchRow.Elevation === row.Elevation &&
      searchRow.PartName.includes("Box")
  );
  if (drawerBox) {
    const boxType = drawerBox.DoorStyle;
    const boxType2 = drawerBox.PartName;
    if (boxType.includes("Endura") && boxType2.includes("File")) {
      return "Melamine Box";
    } else if (boxType.includes("Endura")) {
      return getDrawerFaceInfo(row.Height).simplifiedEnduraBoxType;
    } else {
      return {
        "Melamine Box - Side Mount": "Melamine Box",
        "Melamine Box - Bottom Mount": "Melamine Box",
        "Birch Veneer Box": "Birch Veneer Box",
      }[boxType] || "Unknown Box Type";
    }
  }
  return "No Box (Face Only)";
}

function currentGableAndOpposingGableLeft (row: CPRow) {
  const gableSize = parseFloat(row.Height);
  const gableElevation = parseFloat(row.Elevation);
  const unitHeight = gableSize + gableElevation;
  const L1Depth = row.LineBore1StartStopL ? parseFloat(row.LineBore1StartStopL.split("|")[0].trim()) : 0;
  const L1Start = row.LineBore1StartStopL ? parseFloat(row.LineBore1StartStopL.split("|")[1].trim()) : 0;
  const L1Stop = row.LineBore1StartStopL ? parseFloat(row.LineBore1StartStopL.split("|")[2].trim()) : 0;
  const L2Depth = row.LineBore2StartStopL ? parseFloat(row.LineBore2StartStopL.split("|")[0].trim()) : 0;
  const L2Start = row.LineBore2StartStopL ? parseFloat(row.LineBore2StartStopL.split("|")[1].trim()) : 0;
  const L2Stop = row.LineBore2StartStopL ? parseFloat(row.LineBore2StartStopL.split("|")[2].trim()) : 0;

  const floorWithFloorLeft = gableElevation === 0 && L1Start === 0 && row.LineBore2StartStopL === "";
  const floorWithTopAndBaseLeft = gableElevation === 0 && row.LineBore1StartStopL !== "" && row.LineBore2StartStopL !== "";
  const floorWithWallLeft = gableElevation === 0 && L1Start !== 0 && row.LineBore2StartStopL === "";
  const wallWithTopAndBaseLeft = gableElevation !== 0 && row.LineBore1StartStopL !== "" && row.LineBore2StartStopL !== "";
  const wallWithWallLeft = gableElevation !== 0 && 
    ((row.LineBore1StartStopL === "" && L2Start !== 0) || (L1Start !== 0 && row.LineBore2StartStopL === ""));
  const wallWithFloorLeft = gableElevation !== 0 && 
    ((row.LineBore1StartStopL === "" && L2Start === 0) || (L1Start === 0 && row.LineBore2StartStopL === ""));
  
  const evenGableHeightsLeft = unitHeight === (L1Stop || L2Stop);

  //Left Side Drilling
  if (row.LineBore1StartStopL === "" && row.LineBore2StartStopL === "") {
    return {
      topDownHeightLeft: 0,
      topDownDepthLeft: 0,
      bottomUpHeightLeft: 0,
      bottomUpDepthLeft: 0,
    };
  } else if (row.LineBore1StartStopL !== "" || row.LineBore2StartStopL !== "") {

    if (floorWithFloorLeft === true && evenGableHeightsLeft === true) {
      return {
        topDownHeightLeft: L1Stop - L1Start,
        topDownDepthLeft: L1Depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };
    } else if (floorWithFloorLeft === true && evenGableHeightsLeft === false) {
      return {
        topDownHeightLeft: 0,
        topDownDepthLeft: 0,
        bottomUpHeightLeft: L1Stop,
        bottomUpDepthLeft: L1Depth,
      };

    } else if (floorWithTopAndBaseLeft === true && evenGableHeightsLeft === true) {
      return {
        topDownHeightLeft: L2Stop - L2Start,
        topDownDepthLeft: L2Depth,
        bottomUpHeightLeft: L1Stop,
        bottomUpDepthLeft: L1Depth,
      };
    } else if (floorWithTopAndBaseLeft === true && evenGableHeightsLeft === false) {
      return {
        topDownHeightLeft: (unitHeight - L2Stop) + L2Stop - L2Start,
        topDownDepthLeft: L2Depth,
        bottomUpHeightLeft: L1Stop,
        bottomUpDepthLeft: L1Depth,
      };

    } else if (floorWithWallLeft === true && evenGableHeightsLeft === true) {
      return {
        topDownHeightLeft: L1Stop - L1Start,
        topDownDepthLeft: L1Depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };
    } else if (floorWithWallLeft === true && evenGableHeightsLeft === false) {
      return {
        topDownHeightLeft: (unitHeight - L1Stop) + L1Stop - L1Start,
        topDownDepthLeft: L1Depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };

    } else if (wallWithTopAndBaseLeft === true && evenGableHeightsLeft === true) {
      return {
        topDownHeightLeft: L2Stop - L2Start,
        topDownDepthLeft: L2Depth,
        bottomUpHeightLeft: L1Stop - L1Start,
        bottomUpDepthLeft: L1Depth,
      };
    } else if (wallWithTopAndBaseLeft === true && evenGableHeightsLeft === false) {
      return {
        topDownHeightLeft: (unitHeight - L2Stop) + L2Stop - L2Start,
        topDownDepthLeft: L2Depth, 
        bottomUpHeightLeft: L1Stop - L1Start,
        bottomUpDepthLeft: L1Depth,
      };
      
    } else if (wallWithWallLeft === true && evenGableHeightsLeft === true) {
      const stop = L1Stop === 0 ? L2Stop : L1Stop;
      const start = L1Start === 0 ? L2Start : L1Start;
      const depth = L1Depth === 0 ? L2Depth : L1Depth;
      return {
        topDownHeightLeft: stop - start,
        topDownDepthLeft: depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };
    } else if (wallWithWallLeft === true && evenGableHeightsLeft === false) {
      const stop = L1Stop === 0 ? L2Stop : L1Stop;
      const start = L1Start === 0 ? L2Start : L1Start;
      const depth = L1Depth === 0 ? L2Depth : L1Depth;
      return {
        topDownHeightLeft: (unitHeight - stop) + stop - start,
        topDownDepthLeft: depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };

    } else if (wallWithFloorLeft === true && evenGableHeightsLeft === true) {
      const stop = L1Stop === 0 ? L2Stop : L1Stop;
      const start = L1Start === 0 ? L2Start : L1Start;
      const depth = L1Depth === 0 ? L2Depth : L1Depth;
      return {
        topDownHeightLeft: stop - start,
        topDownDepthLeft: depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };
    } else if (wallWithFloorLeft === true && evenGableHeightsLeft === false) {
      const stop = L1Stop === 0 ? L2Stop : L1Stop;
      const start = L1Start === 0 ? L2Start : L1Start;
      const depth = L1Depth === 0 ? L2Depth : L1Depth;
      return {
        topDownHeightLeft: stop - start,
        topDownDepthLeft: depth,
        bottomUpHeightLeft: 0,
        bottomUpDepthLeft: 0,
      };
    }
  }

  return {
    topDownHeightLeft: 0,
    topDownDepthLeft: 0,
    bottomUpHeightLeft: 0,
    bottomUpDepthLeft: 0,
    topDownHeightRight: 0,
    topDownDepthRight: 0,
    bottomUpHeightRight: 0,
    bottomUpDepthRight: 0,
  };

}

function currentGableAndOpposingGableRight (row: CPRow) {
  const gableSize = parseFloat(row.Height);
  const gableElevation = parseFloat(row.Elevation);
  const unitHeight = gableSize + gableElevation;
  const R1Depth = row.LineBore1StartStopR ? parseFloat(row.LineBore1StartStopR.split("|")[0].trim()) : 0;
  const R1Start = row.LineBore1StartStopR ? parseFloat(row.LineBore1StartStopR.split("|")[1].trim()) : 0;
  const R1Stop = row.LineBore1StartStopR ? parseFloat(row.LineBore1StartStopR.split("|")[2].trim()) : 0;
  const R2Depth = row.LineBore2StartStopR ? parseFloat(row.LineBore2StartStopR.split("|")[0].trim()) : 0;
  const R2Start = row.LineBore2StartStopR ? parseFloat(row.LineBore2StartStopR.split("|")[1].trim()) : 0;
  const R2Stop = row.LineBore2StartStopR ? parseFloat(row.LineBore2StartStopR.split("|")[2].trim()) : 0;

  const floorWithFloorRight = gableElevation === 0 && R1Start === 0 && row.LineBore2StartStopR === "";
  const floorWithTopAndBaseRight = gableElevation === 0 && row.LineBore1StartStopR !== "" && row.LineBore2StartStopR !== "";
  const floorWithWallRight = gableElevation === 0 && R1Start !== 0 && row.LineBore2StartStopR === "";
  const wallWithTopAndBaseRight = gableElevation !== 0 && row.LineBore1StartStopR !== "" && row.LineBore2StartStopR !== "";
  const wallWithWallRight = gableElevation !== 0 && 
    ((row.LineBore1StartStopR === "" && R2Start !== 0) || (R1Start !== 0 && row.LineBore2StartStopR === ""));
  const wallWithFloorRight = gableElevation !== 0 && 
    ((row.LineBore1StartStopR === "" && R2Start === 0) || (R1Start === 0 && row.LineBore2StartStopR === ""));
  
  const evenGableHeightsRight = unitHeight === (R1Stop || R2Stop);

  //Right Side Drilling
  if (row.LineBore1StartStopR === "" && row.LineBore2StartStopR === "") {
    return {
      topDownHeightRight: 0,
      topDownDepthRight: 0,
      bottomUpHeightRight: 0,
      bottomUpDepthRight: 0,
    };
  } else if (row.LineBore1StartStopR !== "" || row.LineBore2StartStopR !== "") {

    if (floorWithFloorRight === true && evenGableHeightsRight === true) {
      return {
        topDownHeightRight: R1Stop - R1Start,
        topDownDepthRight: R1Depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };
    } else if (floorWithFloorRight === true && evenGableHeightsRight === false) {
      return {
        topDownHeightRight: 0,
        topDownDepthRight: 0,
        bottomUpHeightRight: R1Stop,
        bottomUpDepthRight: R1Depth,
      };

    } else if (floorWithTopAndBaseRight === true && evenGableHeightsRight === true) {
      return {
        topDownHeightRight: R2Stop - R2Start,
        topDownDepthRight: R2Depth,
        bottomUpHeightRight: R1Stop,
        bottomUpDepthRight: R1Depth,
      };
    } else if (floorWithTopAndBaseRight === true && evenGableHeightsRight === false) {
      return {
        topDownHeightRight: (unitHeight - R2Stop) + R2Stop - R2Start,
        topDownDepthRight: R2Depth,
        bottomUpHeightRight: R1Stop,
        bottomUpDepthRight: R1Depth,
      };

    } else if (floorWithWallRight === true && evenGableHeightsRight === true) {
      return {
        topDownHeightRight: R1Stop - R1Start,
        topDownDepthRight: R1Depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };
    } else if (floorWithWallRight === true && evenGableHeightsRight === false) {
      return {
        topDownHeightRight: (unitHeight - R1Stop) + R1Stop - R1Start,
        topDownDepthRight: R1Depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };

    } else if (wallWithTopAndBaseRight === true && evenGableHeightsRight === true) {
      return {
        topDownHeightRight: R2Stop - R2Start,
        topDownDepthRight: R2Depth,
        bottomUpHeightRight: R1Stop - R1Start,
        bottomUpDepthRight: R1Depth,
      };
    } else if (wallWithTopAndBaseRight === true && evenGableHeightsRight === false) {
      return {
        topDownHeightRight: (unitHeight - R2Stop) + R2Stop - R2Start,
        topDownDepthRight: R2Depth, 
        bottomUpHeightRight: R1Stop - R1Start,
        bottomUpDepthRight: R1Depth,
      };
      
    } else if (wallWithWallRight === true && evenGableHeightsRight === true) {
      const stop = R1Stop === 0 ? R2Stop : R1Stop;
      const start = R1Start === 0 ? R2Start : R1Start;
      const depth = R1Depth === 0 ? R2Depth : R1Depth;
      return {
        topDownHeightRight: stop - start,
        topDownDepthRight: depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };
    } else if (wallWithWallRight === true && evenGableHeightsRight === false) {
      const stop = R1Stop === 0 ? R2Stop : R1Stop;
      const start = R1Start === 0 ? R2Start : R1Start;
      const depth = R1Depth === 0 ? R2Depth : R1Depth;
      return {
        topDownHeightRight: (unitHeight - stop) + stop - start,
        topDownDepthRight: depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };

    } else if (wallWithFloorRight === true && evenGableHeightsRight === true) {
      const stop = R1Stop === 0 ? R2Stop : R1Stop;
      const start = R1Start === 0 ? R2Start : R1Start;
      const depth = R1Depth === 0 ? R2Depth : R1Depth;
      return {
        topDownHeightRight: stop - start,
        topDownDepthRight: depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };
    } else if (wallWithFloorRight === true && evenGableHeightsRight === false) {
      const stop = R1Stop === 0 ? R2Stop : R1Stop;
      const start = R1Start === 0 ? R2Start : R1Start;
      const depth = R1Depth === 0 ? R2Depth : R1Depth;
      return {
        topDownHeightRight: stop - start,
        topDownDepthRight: depth,
        bottomUpHeightRight: 0,
        bottomUpDepthRight: 0,
      };
    }
  }

  return {
    topDownHeightLeft: 0,
    topDownDepthLeft: 0,
    bottomUpHeightLeft: 0,
    bottomUpDepthLeft: 0,
    topDownHeightRight: 0,
    topDownDepthRight: 0,
    bottomUpHeightRight: 0,
    bottomUpDepthRight: 0,
  };

}


// function processSide(row, side) {
//   const isLeft = side === 'Left';
//   const bore1 = isLeft ? row.LineBore1StartStopL : row.LineBore1StartStopR;
//   const bore2 = isLeft ? row.LineBore2StartStopL : row.LineBore2StartStopR;

//   if (!bore1 || !bore2) {
//     return {
//       topDownHeight: 0,
//       topDownDepth: 0,
//       bottomUpHeight: 0,
//       bottomUpDepth: 0,
//     };
//   }

//   const bore1Array = bore1.split('|').map(str => parseFloat(str.trim()));
//   const bore2Array = bore2.split('|').map(str => parseFloat(str.trim()));

//   const depth = bore1Array[0];
//   const start = bore1Array[1];
//   const stop = bore1Array[2];
//   const depth2 = bore2Array[0];
//   const start2 = bore2Array[1];
//   const stop2 = bore2Array[2];

//   const topDownHeight = stop - start;
//   const bottomUpHeight = stop2 - start2;

//   return {
//     topDownHeight,
//     topDownDepth: depth,
//     bottomUpHeight,
//     bottomUpDepth: depth2,
//   };
// }

// function currentGableAndOpposingGable(row) {
//   const gableSize = parseFloat(row.Height);
//   const gableElevation = parseFloat(row.Elevation);
//   const unitHeight = gableSize + gableElevation;

//   const leftSide = processSide(row, 'Left');
//   const rightSide = processSide(row, 'Right');

//   const evenGableHeightsLeft = unitHeight === (leftSide.topDownHeight || leftSide.bottomUpHeight);
//   const evenGableHeightsRight = unitHeight === (rightSide.topDownHeight || rightSide.bottomUpHeight);

//   // Continue with your remaining logic using leftSide and rightSide objects
// }





export {
  getAttributesFromCPSColumnValue,
  getDoorOpeningStyle,
  getDrawerWoodType,
  getDrawerEdgeTapeType,
  getSimpleSlideTypeName,
  alternateSBCLeftRight,
  alternateSBLeftRight,
  getTapingInstructions,
  //getDoorHandleLocation,
  getBoxType,
  currentGableAndOpposingGableLeft,
  currentGableAndOpposingGableRight,
};
