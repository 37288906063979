import { ProductDataMapping } from "./types";
import * as helpers from "../shared/helpers";
import getExportProductName from "./getExportProductName";
import { Categories, makeCategory } from "../shared/categories";

// prettier-ignore

const productDataMapping: ProductDataMapping = {
  "Gable - Special Drilling": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.getGlideDrillingType(roomName, allData),
        quantity,
        helpers.roundTo3Decimals(length),
        width,
        1,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        "None",
        helpers.roundTo3Decimals(length),
        width,
        "0",
        "0",
        "0",
        "0",
        "None",
        helpers.roundTo3Decimals(length),
        width,
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Steel Angle L Bracket", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Angle Bracket Cover", 1 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 1-1/2 Flat Robertson", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket - Left", 0.5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket Cover - Left", 0.5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket - Right", 0.5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket Cover - Right", 0.5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw with Shoulder", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Wall Rail & Wall Rail Cover", 0.75, "93", helpers.getHardwareColorFromWoodColor(kcdWoodColor), helpers.firstFiveChars(roomName), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Adjustable Shelf": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Shelf Support Metal", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Top Fixed Shelf": (row) => {
    const { roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        "Fixed Shelf",
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Wall Rail & Wall Rail Cover", width, "93", helpers.getHardwareColorFromWoodColor(kcdWoodColor), helpers.firstFiveChars(roomName), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Fixed Shelf": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Fixed Shelf with Puck": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Fixed Shelf with Strip": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(width, quantity), "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Fixed Shelf - One Sided": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        width,
        length,
        "",
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", helpers.calculateExtraHardwareFor18Deep(width, quantity) / 2, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(width, quantity) / 2, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  Kick: (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "Horizontal",
        quantity,
        helpers.setKickHeight(length, width),
        helpers.setKickWidth(length, width),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Shelf Support Clear", 4 * quantity, "Each", makeCategory(Categories.Hardware)]
      ],
    };
  },
  Miscellaneous: (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    const longestSide = length > width ? length : width;
    const shortestSide = length > width ? width : length;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "Length",
        quantity,
        helpers.roundTo3Decimals(longestSide),
        helpers.roundTo3Decimals(shortestSide),
        "Board Only",
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Screw #8 x 1-1/4 Pan Robertson", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Island Gable - Double": (row, allData) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, wallNumber, unitNumber } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.getGlideDrillingType(roomName, allData),
        quantity,
        helpers.roundTo3Decimals(length),
        width,
        width,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  "Island Divider": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, wallNumber, unitNumber } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        helpers.roundTo3Decimals(length),
        helpers.roundTo3Decimals(width),
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 10 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 10 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Corner Gable": (row) => {
    const {
      kcdName,
      roomName,
      wallNumber,
      unitNumber,
      kcdWoodColor,
      quantity,
      length,
      // width,
    } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        quantity,
        helpers.roundTo3Decimals(length),
        "6",
        "1",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Steel Angle L Bracket", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Angle Bracket Cover", 1 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 1-1/2 Flat Robertson", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket - Left", 0.5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket Cover - Left", 0.5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket - Right", 0.5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Suspension Bracket Cover - Right", 0.5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw with Shoulder", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Slanted Shoe Shelf": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "TAG Fence",
        quantity,
        width,
        helpers.roundTo3Decimals(length),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Shelf Support Metal", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 2 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  // @todo: this one is not done, not yet exported from KCD
  "Sliding Shelf": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "Bottom Mount",
        quantity,
        width,
        helpers.roundTo3Decimals(length),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  "Corner Shelf Pie - Fixed": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Corner Shelf Pie - Adjustable": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Shelf Support Metal", 5 * quantity, "Each", makeCategory(Categories.Hardware)]
      ],
    };
  },
  "Corner Shelf Radius - Fixed": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Corner Shelf Radius - Adjustable": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Shelf Support Metal", 5 * quantity, "Each", makeCategory(Categories.Hardware)]
      ],
    };
  },
  "Corner Shelf L Shaped - Fixed": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 5 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 5 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Corner Shelf L Shaped - Adjustable": (row) => {
    const { kcdName, roomName, kcdWoodColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    const leftLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(width) : helpers.roundTo3Decimals(length);
    const rightLength = doorStyle === "Left Corner" ? helpers.roundTo3Decimals(length) : helpers.roundTo3Decimals(width);
    const notchSide = doorStyle.includes("Right") ? "Right" : doorStyle.includes("Left") ? "Left" : "Right";
    const leftDepth = Math.round(parseFloat(handlePosition));
    const rightDepth = Math.round(parseFloat(handleType));
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "6",
        notchSide,
        quantity,
        leftLength,
        rightLength,
        leftDepth,
        rightDepth,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Shelf Support Metal", 5 * quantity, "Each", makeCategory(Categories.Hardware)]
      ],
    };
  },
  "Euro Slab Door": (row) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdDoorColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Vertical",
        quantity,
        helpers.roundTo3Decimals(length),
        helpers.roundTo3Decimals(width),
        doorStyle,
        helpers.TAGHandleSizeTypeMap(handleType),
        handlePosition,
        "2.75",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Half Overlay Soft Close Hinge", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
        [roomName, "Half Overlay Hinge Plate", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Shaker Door": (row) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdDoorColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Shaker Door",
        quantity,
        helpers.roundTo3Decimals(length),
        helpers.roundTo3Decimals(width),
        doorStyle,
        helpers.TAGHandleSizeTypeMap(handleType),
        handlePosition,
        "2.75",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Half Overlay Soft Close Hinge", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
        [roomName, "Half Overlay Hinge Plate", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Shaker Glass Door": (row) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdDoorColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "", // Panel Type
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Shaker Door",
        quantity,
        helpers.roundTo3Decimals(length),
        helpers.roundTo3Decimals(width),
        doorStyle,
        helpers.TAGHandleSizeTypeMap(handleType),
        handlePosition,
        "2.75",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Half Overlay Soft Close Hinge", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
        [roomName, "Half Overlay Hinge Plate", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Terzetto Door": (row) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdDoorColor, quantity, length, width, doorStyle, handlePosition, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Vertical",
        quantity,
        helpers.roundTo3Decimals(length),
        helpers.roundTo3Decimals(width),
        doorStyle,
        helpers.TAGHandleSizeTypeMap(handleType),
        handlePosition,
        "2.75",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Half Overlay Soft Close Hinge", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
        [roomName, "Half Overlay Hinge Plate", helpers.calculateHingeQtyOnDoorSize(length, quantity), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Euro Slab Drawer Face": (row, allData) => {
    const { kcdName, roomName, kcdDoorColor, quantity, length, width, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Horizontal",
        "Center",
        quantity,
        helpers.getDrawerFaceInfo(length).drawerFaceHeight,
        helpers.roundTo3Decimals(width),
        helpers.TAGHandleSizeTypeMap(handleType),
        helpers.getSimplifiedBoxType(roomName, length, allData),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  "Shaker Drawer Face": (row, allData) => {
    const { kcdName, roomName, kcdDoorColor, quantity, length, width, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Shaker Drawer",
        "Center",
        quantity,
        helpers.getDrawerFaceInfo(length).drawerFaceHeight,
        helpers.roundTo3Decimals(width),
        helpers.TAGHandleSizeTypeMap(handleType),
        helpers.getSimplifiedBoxType(roomName, length, allData),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  "Terzetto Drawer Face": (row, allData) => {
    const { kcdName, roomName, kcdDoorColor, quantity, length, width, handleType } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdDoorColor),
        helpers.removeSuffixFromColor(kcdDoorColor),
        "Terzetto",
        "Center",
        quantity,
        helpers.getDrawerFaceInfo(length).drawerFaceHeight,
        helpers.roundTo3Decimals(width),
        helpers.TAGHandleSizeTypeMap(handleType),
        helpers.getSimplifiedBoxType(roomName, length, allData),
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  "Melamine Box - Bottom Mount": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "3/4 White",
        "White Flat Tape",
        "Bottom Mount",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Wood Dowel", helpers.calculateWoodDowelQty(attributes?.height as string, quantity), "Each", makeCategory(Categories.Hardware)], // @todo: Come back and fix this to grab the right stuff
        [roomName, "Screw #6 x 1-1/4 Flat Robertson", 10 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Bottom Mount Slides", quantity, length, makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Melamine Box - Side Mount": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "3/4 White",
        "White Flat Tape",
        "Side Mount SC - Repon",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Wood Dowel", helpers.calculateWoodDowelQty(attributes?.height as string, quantity), "Each", makeCategory(Categories.Hardware)], // @todo: Come back and fix this to grab the right stuff
        [roomName, "Screw #6 x 1-1/4 Flat Robertson", 10 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Side Mount Soft Close Slides", quantity, length, makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Birch Veneer Box": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "3/4 White",
        "Veneer Flat Tape",
        "Side Mount SC - Repon",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Wood Dowel", helpers.calculateWoodDowelQty(attributes?.height as string, quantity), "Each", makeCategory(Categories.Hardware)], //**Come back and fix this to grab the right stuff */
        [roomName, "Screw #6 x 1-1/4 Flat Robertson", 10 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Side Mount Soft Close Slides", quantity, length, makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Endura Box - White": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "5/8 White",
        "White Flat Tape",
        "Endura Undermount SC",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getEnduraDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Screw #6 x 1/2 Pan Robertson", 12 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Endura Box - Grey": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "5/8 Grey",
        "Grey Flat Tape",
        "Endura Undermount SC",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getEnduraDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Screw #6 x 1/2 Pan Robertson", 12 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
      ],
    };
  },
  "File Drawer": (row, allData) => {
    const { kcdName, roomName, wallNumber, unitNumber, quantity, length, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        "3/4 White",
        "White Flat Tape",
        "Side Mount SC - Repon",
        helpers.TAGHandleSizeTypeMap(helpers.getHandleType(roomName, wallNumber, unitNumber, allData)),
        "Center",
        quantity,
        length,
        helpers.getDrawerBoxInfo(attributes?.height),
        width,
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Wood Dowel", helpers.calculateWoodDowelQty(attributes?.height as string, quantity), "Each", makeCategory(Categories.Hardware)], //**Come back and fix this to grab the right stuff */
        [roomName, "Screw #6 x 1-1/4 Flat Robertson", 10 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8 x 3/4 Pan Robertson", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Side Mount Slides", quantity, length, makeCategory(Categories.Hardware)],
      ],
    };
  },
  Filler: (row) => {
    const { roomName, kcdWoodColor, quantity, length, width } = row;
    const longestSide = length > width ? length : width;
    const shortestSide = length > width ? width : length;
    return {
      product: [
        roomName,
        "Miscellaneous",
        helpers.removeSuffixFromColor(kcdWoodColor),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "Length",
        quantity,
        longestSide,
        shortestSide,
        "Board Only",
        helpers.firstFiveChars(roomName),
        makeCategory(Categories.Wood)
      ],
      hardwares: [],
    };
  },
  Backer: (row) => {
    const { kcdName, roomName, wallNumber, unitNumber, kcdWoodColor, quantity, length, width } = row;
    return {
      product: [
        roomName,
        getExportProductName(kcdName),
        helpers.removeSuffixFromColor(kcdWoodColor),
        "", // Get rid of when CPS Bridge is up
        quantity,
        helpers.roundTo3Decimals(length),
        width,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.Wood)
      ],
      hardwares: [
        [roomName, "Titus Screw-in Dowel", 6 * quantity, "Each", makeCategory(Categories.Hardware)],
        [roomName, "Titus Cam Fitting", 6 * quantity, "Each", helpers.getHardwareColorFromWoodColor(kcdWoodColor), makeCategory(Categories.Hardware)],
      ],
    };
  },
  "Closet Pole": (row) => {
    const { roomName, wallNumber, unitNumber, quantity, width } = row;
    const roundedWidth = helpers.roundTo2Decimals((Number(width)).toString());
    return {
      product: [
        roomName,
        "Closet Pole",
        quantity,
        roundedWidth,
        "Signature Pole - Matte Aluminum",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [
        [roomName, "Closet Pole Cup", 2 * quantity, "Signature Pole Cup - Matte Aluminum", makeCategory(Categories.PolesAndCups)],
      ],
    };
  },

  /**Template or Model Example for Accessories */

  // "Engage Divided Deep Drawer": (row) => {
  //   const { kcdName, roomName, quantity } = row;
  //   const attributes = helpers.getAttributesFromKCDProductName(kcdName);
  //   const fabric = helpers.getFabricName(attributes?.fabric as string);
  //   const finish = helpers.getFinishName(attributes?.finish as string);
  //   const widthFabricFinish = `${attributes?.width}" - ${fabric} - ${finish}`;
  //   return {
  //     product: [
  //       roomName,
  //       "Engage Divided Deep Drawer",
  //       quantity,
  //       widthFabricFinish,
  //       makeCategory(Categories.Accessories)
  //     ],
  //     hardwares: [],
  //   };
  // },

  /**--------------------------Misc Accessories ----------------------------*/
  "Oval Chrome Pole": (row) => {
    const { kcdName, roomName, quantity, width, wallNumber, unitNumber } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const trimmedWidth = helpers.roundTo3Decimals((Number(width) - 0.3125).toString());
    return {
      product: [
        roomName,
        "Oval Chrome Pole",
        quantity,
        trimmedWidth,
        "Chrome",
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [[roomName, "Oval Pole Cup", quantity*2, finish, makeCategory(Categories.PolesAndCups)]],
    };
  },
  "Round Pole": (row) => {
    const { kcdName, roomName, quantity, width, wallNumber, unitNumber } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const trimmedWidth = helpers.roundTo3Decimals((Number(width) - 0.25).toString());
    return {
      product: [
        roomName,
        "Round Pole",
        quantity,
        trimmedWidth,
        finish,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [[roomName, "Round Pole Cup", quantity*2, finish, makeCategory(Categories.PolesAndCups)]],
    };
  },
  "Elite Pole": (row) => {
    const { kcdName, roomName, quantity, width, wallNumber, unitNumber } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const trimmedWidth = helpers.roundTo3Decimals((Number(width) - 0.25).toString());
    return {
      product: [
        roomName,
        "Elite Pole",
        quantity,
        trimmedWidth,
        finish,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [[roomName, "Elite Pole Cup", quantity*2, finish, makeCategory(Categories.PolesAndCups)]],
    };
  },
  "Signature Pole": (row) => {
    const { kcdName, roomName, quantity, width, wallNumber, unitNumber } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const trimmedWidth = helpers.roundTo3Decimals((Number(width) - 0.25).toString());
    return {
      product: [
        roomName,
        "Signature Pole",
        quantity,
        trimmedWidth,
        finish,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [[roomName, "Signature Pole Cup", quantity*2, finish, makeCategory(Categories.PolesAndCups)]],
    };
  },
  "Leather Wrapped Pole": (row) => {
    const { kcdName, roomName, quantity, width, wallNumber, unitNumber } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const trimmedWidth = helpers.roundTo3Decimals((Number(width) - 0.25).toString());
    return {
      product: [
        roomName,
        "Leather Wrapped Pole",
        quantity,
        trimmedWidth,
        finish,
        helpers.firstFiveChars(roomName),
        wallNumber,
        unitNumber,
        makeCategory(Categories.PolesAndCups)
      ],
      hardwares: [[roomName, "Elite Pole Cup", quantity*2, finish, makeCategory(Categories.PolesAndCups)]],
    };
  },
  "Pull-out Pant Rack": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFinish = `${wholewidth}" - ${finish}`;
    return {
      product: [
        roomName,
        "Pull-out Pant Rack",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Pull-out Hamper": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFinish = `${wholewidth}" - ${finish}`;
    return {
      product: [
        roomName,
        "Pull-out Hamper",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Elite Shoe Fence": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width)+1);
    const widthFinish = `${wholewidth}" - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Shoe Fence",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Hooks and Board ----------------------------*/
  "Leather-Wrapped Cleat Board" : (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    return {
      product: [
        roomName,
        "Leather-Wrapped Cleat Board" ,
        quantity,
        leather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "End Caps for Leather-Wrapped Cleat Board", quantity, finish, makeCategory(Categories.Accessories)]],
    };
  },
  "Tie Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Tie Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Belt Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Belt Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Double Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Double Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Coat Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Coat Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Compact Coat Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Compact Coat Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Waterfall Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Waterfall Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Broom Hook": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Broom Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Racks and Valets ----------------------------*/
  "Elite Tie Rack": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthFinish = `14" - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Tie Rack",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Belt Rack": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthFinish = `14" - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Belt Rack",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Scarf Rack": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthFinish = `14" - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Scarf Rack",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Valet Rod": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthFinish = `14" - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Valet Rod",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Valet Pin": (row) => {
    const { kcdName, roomName, quantity} = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Elite Valet Pin",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Mirrors ----------------------------*/
  "Elite Fixed Mirror - 14\"": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Elite Fixed Mirror - 14\"",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Fixed Mirror - 35\"": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Elite Fixed Mirror - 35\"",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Elite Fixed Mirror - 47\"": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Elite Fixed Mirror - 47\"",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Engage Items ----------------------------*/
  "Engage Divided Shelf": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Divided Shelf",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Folding Station": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Folding Station",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Storage Box": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const wholewidth = Math.round(Number(width));
    const widthFabric = `${wholewidth}" - ${fabric}`;
    return {
      product: [
        roomName,
        "Engage Storage Box",
        quantity,
        widthFabric,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Engage Jewelry Organizer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Jewelry Organizer",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Divided Deep Drawer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Divided Deep Drawer",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Lingerie Drawer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Lingerie Drawer",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Pant Organizer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFinish = `${wholewidth}" - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Pant Organizer",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Shoe Organizer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFinish = `${wholewidth}" - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Shoe Organizer",
        quantity,
        widthFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  "Engage Laundry Organizer": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const fabric = helpers.getFabricName(attributes?.fabric as string);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const wholewidth = Math.round(Number(width));
    const widthFabricFinish = `${wholewidth}" - ${fabric} - ${finish}`;
    return {
      product: [
        roomName,
        "Engage Laundry Organizer",
        quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]],
    };
  },
  /**--------------------------Track Wall Items ----------------------------*/
  "Paper Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Paper Tray",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Pencil Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Pencil Tray",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Small Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Small Tray",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Upright Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Upright Tray",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "File Hanger": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "File Hanger",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Track Panel": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Track Panel",
        quantity,
        "8ft - Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Tie Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Tie Holder",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Belt Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Belt Holder",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Double Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Double Holder",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Coat Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Coat Holder",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Bracelet Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Bracelet Holder",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Valet Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Valet Hook",
        quantity,
        "Matte Aluminum",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Small Storage Bins": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Clear Storage Bins",
        quantity,
        "5\" x 4\" x 3\"",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Medium Storage Bins": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Clear Storage Bins",
        quantity,
        "7\" x 4\" x 3\"",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Large Storage Bins": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Clear Storage Bins",
        quantity,
        "10\" x 5\" x 5\"",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Hand Tool Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Hand Tool Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Paper Organizer Set": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Paper Organizer Set",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Single Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Single Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Wire Basket Hooks": (row) => { // Do they come in as Pairs for singles?
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Wire Basket Hooks",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Screwdriver Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Screwdriver Holder",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Wrench Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Wrench Holder",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Pliers Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Pliers Holder",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "14\" Utility Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "14\" Utility Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Shelf Bracket": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Shelf Bracket",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Fishing Rod Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Fishing Rod Holder",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "16\" Utility Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "16\" Utility Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Activity Rack": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Activity Rack",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "5\" Utility Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "5\" Utility Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Hose Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Hose Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Bike Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Bike Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Garden Tool Hook": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Garden Tool Hook",
        quantity,
        "Black",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Symphony with Accessories ----------------------------*/
  "Symphony Shoe Hook": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Symphony Shoe Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Coat Hook": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Symphony Coat Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Triple Hook": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Symphony Triple Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Jewelry Hook": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    return {
      product: [
        roomName,
        "Symphony Jewelry Hook",
        quantity,
        finish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Leather Pocket": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    return {
      product: [
        roomName,
        "Symphony Leather Pocket",
        quantity,
        leather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Pencil Holder": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Symphony Pencil Holder",
        quantity,
        "White",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Container Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Symphony Container Tray",
        quantity,
        "White",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Paper Tray": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Symphony Paper Tray",
        quantity,
        "White",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony Tablet Stand": (row) => {
    const { roomName, quantity } = row;
    return {
      product: [
        roomName,
        "Symphony Tablet Stand",
        quantity,
        "White",
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony SOLO": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const widthFinishLeather = `${wholewidth}" - ${finish} - ${leather}`;
    return {
      product: [
        roomName,
        "Symphony SOLO",
        quantity,
        widthFinishLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony DUET": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const heightFinish = `7" - ${finish}`;
    const widthLeather = `${wholewidth}" - ${leather} (2 pack)`;
    return {
      product: [
        roomName,
        "Symphony DUET",
        quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony QUARTET": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const heightFinish = `19" - ${finish}`;
    const widthLeather = `${wholewidth}" - ${leather} (4 pack)`;
    return {
      product: [
        roomName,
        "Symphony QUARTET",
        quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony OCTAVE": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const heightFinish = `42" - ${finish}`;
    const widthLeather = `${wholewidth}" - ${leather} (8 pack)`;
    return {
      product: [
        roomName,
        "Symphony OCTAVE",
        quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony DODECA": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const heightFinish = `66" - ${finish}`;
    const widthLeather = `${wholewidth}" - ${leather} (12 pack)`;
    return {
      product: [
        roomName,
        "Symphony DODECA",
        quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  "Symphony DUET Surface Mount": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width)) === 14 ? 13 : Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const heightFinish = `12" - ${finish}`;
    const widthLeather = `${wholewidth}" - ${leather} (2 pack)`;
    return {
      product: [
        roomName,
        "Symphony DUET Surface Mount",
        quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [],
    };
  },
  /**--------------------------Illuminated Glass Shelves ----------------------------*/
  "Illuminated Glass Shelf - 14\" Deep": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthColorLightFinish = `${wholewidth}" Width - ${attributes?.colorlight} White - ${finish}`;
    return {
      product: [
        roomName,
        "Illuminated Glass Shelf - 14\" Deep",
        quantity,
        widthColorLightFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]], //Add filler if imported
    };
  },
  "Illuminated Glass Shelf - 21\" Deep": (row) => {
    const { kcdName, roomName, quantity, width } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const wholewidth = Math.round(Number(width));
    const finish = helpers.getFinishName(attributes?.finish as string);
    const widthColorLightFinish = `${wholewidth}" Width - ${attributes?.colorlight} White - ${finish}`;
    return {
      product: [
        roomName,
        "Illuminated Glass Shelf - 21\" Deep",
        quantity,
        widthColorLightFinish,
        makeCategory(Categories.Accessories)
      ],
      hardwares: [[roomName, "Euro Screw", 4 * quantity, "Each", makeCategory(Categories.Hardware)]], //Add filler if imported
    };
  },
  /**--------------------------Handles and Pulls ----------------------------*/
  "Elite Classic Aluminum Pull": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinish = `${size} - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Classic Aluminum Pull",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Transitional Arched Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `160mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Transitional Arched Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Transitional Bow Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `128mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Transitional Bow Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Transitional Dished Knob": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `0mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Transitional Dished Knob",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Transitional Dropped Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `160mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Transitional Dropped Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Modern Dropped Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `128mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Modern Dropped Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Transitional Dropped Pull": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `16mm H - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Transitional Dropped Pull",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Classic Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinish = `${size} - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Classic Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Modern Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinish = `${size} - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Modern Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Modern Knob": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `16mm V - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Modern Knob",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Classic Round Knob": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `0mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Classic Round Knob",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Classic Square Knob": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `0mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Classic Square Knob",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Leather Wrapped Pull": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinishLeather = `${size} - ${finish} - ${leather}`;
    return {
      product: [
        roomName,
        "Leather Wrapped Pull",
        quantity,
        sizeFinishLeather,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Leather Wrapped Modern Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinishLeather = `${size} - ${finish} - ${leather}`;
    return {
      product: [
        roomName,
        "Leather Wrapped Modern Handle",
        quantity,
        sizeFinishLeather,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Traditional Round Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinish = `${size} - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Traditional Round Handle",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Elite Traditional Round Knob": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const sizeFinish = `0mm - ${finish}`;
    return {
      product: [
        roomName,
        "Elite Traditional Round Knob",
        quantity,
        sizeFinish,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },
  "Leather Wrapped Traditional Handle": (row) => {
    const { kcdName, roomName, quantity } = row;
    const attributes = helpers.getAttributesFromKCDProductName(kcdName);
    const finish = helpers.getFinishName(attributes?.finish as string);
    const leather = helpers.getLeatherName(attributes?.leather as string);
    const size = helpers.getSizeName(attributes?.size as string);
    const sizeFinishLeather = `${size} - ${finish} - ${leather}`;
    return {
      product: [
        roomName,
        "Leather Wrapped Traditional Handle",
        quantity,
        sizeFinishLeather,
        makeCategory(Categories.HandlesAndKnobs)
      ],
      hardwares: [],
    };
  },

};

export default productDataMapping;
