/**
 * Returns the new name for the output CSV.
 *
 * @param {String} name
 * @returns New name for output CSV
 */
const getExportProductName = (name: string): string => {
  // prettier-ignore
  const nameMatchers = new Map([
    [/^(.*?)Adjustable Shelf(.*?)$/, "Adjustable Shelf"],
    [/^(.*?)Backing(.*?)$/, "Backer"],
    [/^(.*?)Cab Door(.*?)$/, "Cab Door"],
    [/^(.*?)Vertical Panel - Corner(.*?)$/, "Corner Gable"],
    [/^(.*?)Vertical Panel - Corner - Wall(.*?)$/, "Corner Gable"],
    [/^(.*?)L Adj Shelf(.*?)$/, "Corner Shelf L Shaped - Adjustable"],
    [/^(.*?)L Fixed Shelf(.*?)$/, "Corner Shelf L Shaped - Fixed"],
    [/^(.*?)Pie Adj Shelf(.*?)$/, "Corner Shelf Pie - Adjustable"],
    [/^(.*?)Pie Fixed Shelf(.*?)$/, "Corner Shelf Pie - Fixed"],
    [/^(.*?)L Adj Shelf - Radius(.*?)$/, "Corner Shelf Radius - Adjustable"],
    [/^(.*?)L Fixed Shelf - Radius(.*?)$/, "Corner Shelf Radius - Fixed"],
    [/^(.*?)Drawer Large Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer Medium Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer Medium Large Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer Small Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer Small Medium Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer X Large Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Drawer X Large - File Box(.*?)$/, "File Drawer"],
    [/^(.*?)Drawer X Small Box(.*?)$/, "Drawer Box"],
    [/^(.*?)Filler Panel(.*?)$/, "Filler"],
    [/^(.*?)Bottom Fixed Shelf(.*?)$/, "Fixed Shelf"],
    [/^(.*?)Fixed Flat Shoe Shelf(.*?)$/, "Fixed Shelf"],
    [/^(.*?)Fixed Shelf(.*?)$/, "Fixed Shelf"],
    [/^(.*?)Fixed Shelf - BB Notch(.*?)$/, "Fixed Shelf"],
    [/^(.*?)Top Fixed Shelf(.*?)$/, "Fixed Shelf"],
    [/^(.*?)Filler Shelf(.*?)$/, "Fixed Shelf - One Sided"],
    [/^(.*?)Fixed Shelf with Puck(.*?)$/, "Fixed Shelf with Puck"],
    [/^(.*?)Top Fixed Shelf with Puck(.*?)$/, "Fixed Shelf with Puck"],
    [/^(.*?)Fixed Shelf with Strip(.*?)$/, "Fixed Shelf with Strip"],
    [/^(.*?)Top Fixed Shelf with Strip(.*?)$/, "Fixed Shelf with Strip"],
    [/^(.*?)Vertical Panel - Floor(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Floor - Partial(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Floor - Staggered(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - VS Bottom(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - VS Top(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Radius(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Radius - Partial(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Radius - Staggered(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Straight(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Straight - Partial(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Vertical Panel - Wall - Straight - Staggered(.*?)$/, "Gable - Special Drilling"],
    [/^(.*?)Glass Shelf(.*?)$/, "Glass Shelf"],
    [/^(.*?)Back Panel(.*?)$/, "Island Divider"],
    [/^(.*?)Vertical Panel - Island(.*?)$/, "Island Gable - Double"],
    [/^(.*?)Toe Kick Large(.*?)$/, "Kick"],
    [/^(.*?)Toe Kick Medium(.*?)$/, "Kick"],
    [/^(.*?)Toe Kick Small(.*?)$/, "Kick"],
    [/^(.*?)Toe Kick XSmall(.*?)$/, "Kick"],
    [/^(.*?)Melamine(.*?)$/, "Miscellaneous"],
    [/^(.*?)Slanted Shelf with Fence(.*?)$/, "Slanted Shoe Shelf"],
    [/^(.*?)Sliding Shelf(.*?)$/, "Sliding Shelf"],
    [/^(.*?)Tilt Down Hamper(.*?)$/, "Tilt Down Hamper"],
    [/^(.*?)Steel Angle L Bracket(.*?)$/, "Steel Angle L Bracket"],
    [/^(.*?)Elite Shoe Fence(.*?)$/, "Elite Shoe Fence"],
    [/^(.*?)Symphony Coat Hook - (.*?)$/, "Symphony Coat Hook"],
    [/^(.*?)Symphony Container Tray - (.*?)$/, "Symphony Container Tray"],
    [/^(.*?)Symphony Jewelry Hook - (.*?)$/, "Symphony Jewelry Hook"],
    [/^(.*?)Symphony Leather Pocket - (.*?)$/, "Symphony Leather Pocket"],
    [/^(.*?)Symphony Mirror Clips - (.*?)$/, "Symphony Mirror Clips"],
    [/^(.*?)Symphony Paper Tray - (.*?)$/, "Symphony Paper Tray"],
    [/^(.*?)Symphony Pencil Holder - (.*?)$/, "Symphony Pencil Holder"],
    [/^(.*?)Symphony Post Extensions - (.*?)$/, "Symphony Post Extensions"],
    [/^(.*?)Symphony Shoe Hook - (.*?)$/, "Symphony Shoe Hook"],
    [/^(.*?)Symphony Tablet Stand - (.*?)$/, "Symphony Tablet Stand"],
    [/^(.*?)Symphony Triple Hook - (.*?)$/, "Symphony Triple Hook"],
    [/^(.*?)Symphony Wall Organizer - Hook(.*?)$/, "Symphony Wall Organizer - Hook"],
  ]);
  for (const [key, value] of nameMatchers.entries()) {
    if (key.test(name)) {
      return value;
    }
  }
  return name;
};

export default getExportProductName;




