import Bridge from "../Bridge";
import getExportProductName from "./getExportProductName";
import { CPRow, ProductHardwareOutput } from "./types";
import { BridgeSoftware } from "../types";
import { Categories } from "../shared/categories";
import { productDataMapping } from "./productDataMapping";
import { productHardwareMapping } from "./productHardwareMapping";

class ClosetProBridge extends Bridge {
  public name: BridgeSoftware = "closetPro";

  csvHeaders() {
    return null; // Headers are in the CSV already
  }

  /**
   * Override the default combineInputFiles method to remove the header row
   * from all but the first file for ClosetProBridge.
   *
   * @returns {Promise<string>} - The combined contents of all input files
   */
  async combineInputFiles(): Promise<string> {
    // Convert inputFiles to array of strings
    const fileContents = await Promise.all(
      this.inputFiles.map((file) => file.text())
    );

    // Remove the header row from all but the first file
    const combined = fileContents
      .map((content, index) => {
        const lines = content.split("\n");
        return index === 0 ? lines : lines.slice(1);
      })
      .flat()
      .join("\n");

    return combined;
  }

  static getBridgeCategoryOptions() {
    return {
      [Categories.Wood]: { isEnabled: true, canChange: true },
      [Categories.HandlesAndKnobs]: { isEnabled: true, canChange: true },
      [Categories.PolesAndCups]: { isEnabled: true, canChange: true },
      [Categories.Accessories]: { isEnabled: true, canChange: true },
      [Categories.Hardware]: { isEnabled: true, canChange: true },
    };
  }

  /**
   * @param {Array<Object>} parsedCSV
   * @returns {Promise<Array<Object>>} - The transformed CSV data
   */
  async transformCSV(parsedCSV: CPRow[]): Promise<Array<object>> {
    const productRows: any = [];
    parsedCSV.forEach((row: CPRow) => {
      const newProductNamePartName = getExportProductName(row.PartName);
      const match = productDataMapping.find((mapping) => {
        return (
          mapping.matchNames.includes(newProductNamePartName) ||
          mapping.matchNames.includes(row.PartName)
        );
      });

      if (match) {
        const output = match.outputFunction({
          row,
          allRows: parsedCSV,
          options: this.options,
        });
        if (output) productRows.push(output);
      } else {
        console.log(
          "no output function found for:",
          JSON.stringify({
            newProductNamePartName,
            PartExport: row.PartExport,
            PartName: row.PartName,
          })
        );
      }
    });

    // Run through to add hardware to the end of the data
    let hardwareRows: ProductHardwareOutput = [];
    parsedCSV.forEach((row: CPRow) => {
      const newProductNamePartName = getExportProductName(row.PartName);
      const match = productHardwareMapping.find((mapping) => {
        return (
          mapping.matchNames.includes(newProductNamePartName) ||
          mapping.matchNames.includes(row.PartName)
        );
      });

      if (match) {
        const output = match.outputFunction({
          row,
          allRows: parsedCSV,
          options: this.options,
        });
        if (output) {
          hardwareRows = [...hardwareRows, ...output];
        }
      }
    });

    const finalRows = [...productRows, ...hardwareRows];

    return finalRows;
  }
}

export default ClosetProBridge;
