import PropTypes from "prop-types";

function SoftwareItem({
  label,
  value,
  logo,
  extraClass = "",
  onSelect,
  disabled = false,
}: {
  label: string;
  value: string;
  logo: string;
  extraClass?: string;
  onSelect: (value: string) => void;
  disabled?: boolean;
}) {
  return (
    <label className={`inline-flex items-center ${extraClass}`}>
      <input
        type="radio"
        className="form-radio h-5 w-5 text-blue-600"
        name="designSoftware"
        value={value}
        onChange={() => onSelect(value)}
        disabled={disabled}
      />
      <img src={logo} alt={label} className="ml-2 max-h-[24px]" />
      <span className="ml-1">{label}</span>
    </label>
  );
}

function SoftwareSelector({ onSelect }: { onSelect: (value: string) => void }) {
  return (
    <div className="space-y-2 flex flex-col gap-2 mt-2">
      <SoftwareItem
        label="KCD"
        value="kcd"
        logo="/kcd-logo.png"
        extraClass="mr-5"
        onSelect={onSelect}
      />
      <SoftwareItem
        label="ClosetPro"
        value="closetPro"
        logo="/closet-pro-logo.png"
        extraClass="mr-5"
        onSelect={onSelect}
      />
      <SoftwareItem
        label="LiveStorage (Coming Soon)"
        value="liveStorage"
        logo="/livestorage-logo.png"
        onSelect={onSelect}
        disabled={true}
      />
    </div>
  );
}

SoftwareItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SoftwareSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SoftwareSelector;
