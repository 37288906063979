import Bridge from "../Bridge";
import { Categories } from "../shared/categories";
import { BridgeSoftware } from "../types";
import deduplicateHardwareItems from "./deduplicateHardwareItems";
import getExportProductName from "./getExportProductName";
import productDataMapping from "./productDataMapping";
import updateHardwareQuantities from "./updateHardwareQuantities";
import { KCDRow, OutputRow } from "./types";

class KCDBridge extends Bridge {
  public name: BridgeSoftware = "kcd";

  csvHeaders() {
    /** Note: These stay synced with the type in ./types.ts */
    /** @todo: Find a way to share the definition between type and array here. */
    return [
      "kcdName",
      "roomName",
      "wallNumber",
      "unitNumber",
      "kcdWoodColor",
      "kcdDoorColor",
      "quantity",
      "length",
      "width",
      "depth",
      "cost",
      "filename",
      "oldHandleSize",
      "doorStyle",
      "handlePosition",
      "handleType",
      "handleOrientation",
    ];
  }

  static getBridgeCategoryOptions() {
    return {
      [Categories.Wood]: { isEnabled: true, canChange: true },
      [Categories.HandlesAndKnobs]: { isEnabled: true, canChange: true },
      [Categories.PolesAndCups]: { isEnabled: true, canChange: true },
      [Categories.Accessories]: { isEnabled: true, canChange: true },
      [Categories.Hardware]: { isEnabled: true, canChange: true },
    }
  }

  async transformCSV(parsedCSV: KCDRow[]): Promise<OutputRow[]> {
    let hardwareRows: any = [];
    let newData = parsedCSV.map((row: KCDRow): OutputRow => {
      const outputFunction =
        productDataMapping[row.kcdName] ||
        productDataMapping[getExportProductName(row.kcdName)];

      if (outputFunction) {
        const output = outputFunction(row, parsedCSV);
        if (output.hardwares) {
          hardwareRows = hardwareRows.concat(output.hardwares);
        }
        return output.product;
      } else {
        console.log("no output function for", { kcdName: row.kcdName });
      }
      return [];
    });

    // Remove duplicates from hardwareRows
    hardwareRows = deduplicateHardwareItems(hardwareRows);

    // Round up / down the suspension brackets and covers
    hardwareRows = updateHardwareQuantities(hardwareRows);

    // add hardware to end of newData
    const finalData = newData.concat(hardwareRows);

    return finalData;
  }
}

export default KCDBridge;
