import ClosetProBridge from "./closet-pro/ClosetProBridge";
import KCDBridge from "./kcd/KCDBridge";
import { BridgeCategoryOptions, BridgeOptions, BridgeSoftware } from "./types";

class BridgeFactory {
  static createBridge(
    inputFiles: File[],
    software: BridgeSoftware,
    options: BridgeOptions
  ): KCDBridge | ClosetProBridge | null {
    switch (software) {
      case "kcd":
        return new KCDBridge(inputFiles, software, options);
      case "closetPro":
        return new ClosetProBridge(inputFiles, software, options);
      default:
        console.error(`[BridgeFactory] Unknown software: ${software}`);
        return null;
    }
  }

  static getBridgeCategoryOptions(
    software: BridgeSoftware
  ): BridgeCategoryOptions | null {
    switch (software) {
      case "kcd":
        return KCDBridge.getBridgeCategoryOptions();
      case "closetPro":
        return ClosetProBridge.getBridgeCategoryOptions();
      default:
        console.error(
          `[BridgeFactory] Unknown software for \`getBridgeCategoryOptions\`: ${software}`
        );
        return null;
    }
  }
}

export default BridgeFactory;
