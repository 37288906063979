import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./components/App.tsx";
import "./index.css";

Sentry.init({
  dsn: "https://15b338d071fbf78813c727c0ca087412@o4506319140683776.ingest.sentry.io/4506319141928960",
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
