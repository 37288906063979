const OutputTable = ({
  data,
  className,
}: {
  data: string[][];
  className: string;
}) => {
  const filteredData = data.filter((row: string[]) => row.length > 0);
  return (
    <div className={`overflow-x-auto ${className}`}>
      <table className="min-w-full table-auto">
        <tbody>
          {filteredData.map((row: string[], rowIndex: number) => (
            <tr key={`${rowIndex}`}>
              {row.map((cell: string, cellIndex: number) => (
                <td
                  key={`${rowIndex}-${cellIndex}`}
                  className="px-4 py-2 border border-gray-300 whitespace-nowrap text-sm"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OutputTable;
