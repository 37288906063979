import getExportProductName from "../kcd/getExportProductName";
import { KCDRow } from "../kcd/types";


/**
 * Get new color name for export
 * It takes off the last ' - XX' from the color name
 */
function removeSuffixFromColor(color: string): string {
  return color.replace(/ - [A-Z]{2}$/, "");
}

const glideDrillingTypeMap = new Map([
  ["Melamine Box - Bottom Mount", "Bottom Mount"],
  ["Birch Veneer Box", "Side Mount SC - Repon"],
  ["Melamine Box - Side Mount", "Side Mount SC - Repon"],
  ["File Drawer", "Side Mount SC - Repon"],
  ["Endura Box - Grey", "Endura Undermount SC"],
  ["Endura Box - White", "Endura Undermount SC"],
]);
function getGlideDrillingType(roomName: string, allData: KCDRow[]): string {
  const drawers = allData.filter((row: KCDRow) => {
    const inRoom = row.roomName === roomName;
    const isDrawerBox = row.kcdName.includes("DBox");
    const hasSlides = row.kcdName.includes("w/ Slide");
    return inRoom && isDrawerBox && hasSlides;
  });

  if (drawers.length === 0) {
    return "";
  }

  // find the most common drawer type
  const drawerNames = drawers.map((drawerRow: KCDRow) =>
    getExportProductName(drawerRow.kcdName)
  );
  const drawerNameCounts = drawerNames.reduce(
    (acc: Record<string, number>, name: string) => {
      acc[name] = acc[name] ? acc[name] + 1 : 1;
      return acc;
    },
    {}
  );
  const mostCommonDrawerName = Object.keys(drawerNameCounts).reduce((a, b) =>
    drawerNameCounts[a] > drawerNameCounts[b] ? a : b
  );

  return (
    glideDrillingTypeMap.get(mostCommonDrawerName) ||
    "ERROR Glide Type Not Found"
  );
}

const simplifiedBoxTypeMap = new Map([
  ["Melamine Box - Bottom Mount", "Melamine Box"],
  ["Birch Veneer Box", "Birch Veneer Box"],
  ["Melamine Box - Side Mount", "Melamine Box"],
  ["File Drawer", "Melamine Box"],
]);
function getSimplifiedBoxType(
  roomName: string,
  length: string,
  allData: KCDRow[]
): string {
  const drawers = allData.filter((row: KCDRow) => {
    const inRoom = row.roomName === roomName;
    const isDrawerBox = row.kcdName.includes("DBox");
    return inRoom && isDrawerBox;
  });

  if (drawers.length === 0) {
    return "No Box (Face Only)";
  }

  // find the most common drawer type
  const drawerNames = drawers.map((drawerRow: KCDRow) =>
    getExportProductName(drawerRow.kcdName)
  );
  const drawerNameCounts = drawerNames.reduce(
    (acc: Record<string, number>, name: string) => {
      acc[name] = acc[name] ? acc[name] + 1 : 1;
      return acc;
    },
    {}
  );
  const mostCommonDrawerName = Object.keys(drawerNameCounts).reduce((a, b) =>
    drawerNameCounts[a] > drawerNameCounts[b] ? a : b
  );

  return (
    simplifiedBoxTypeMap.get(mostCommonDrawerName) ||
    getDrawerFaceInfo(length).simplifiedEnduraBoxType ||
    "ERROR Simplified Box Type Not Found"
  );
}

function roundTo3Decimals(numString: string): string {
  const num = parseFloat(numString);
  return Number(num).toFixed(3);
}

function roundTo2Decimals(numString: string): string {
  const num = parseFloat(numString);
  return Number(num).toFixed(2);
}

function firstFiveChars(str: string): string {
  return str.slice(0, 5);
}

function getHardwareColorFromWoodColor(kcdWoodColor: string): string {
  const woodColor = removeSuffixFromColor(kcdWoodColor);
  const colorMap = new Map([
    ["White", "White"],
    ["Antique White", "Almond"],
    ["Black", "Black"],
    ["Brushed Aluminum", "Gray"],
    ["Chocolate Pear", "Bronze"],
    ["Summer Drops", "Gray"],
    ["Shadow", "Gray"],
    ["Brava White", "White"],
    ["Brava Black", "Black"],
    ["Frost Flower", "White"],
    ["Morning Dew", "Gray"],
    ["Midnight Sun", "Dark Gray"],
    ["Evening Star", "Dark Gray"],
    ["Day Break", "Gray"],
    ["Latitude North", "White"],
    ["Latitude West", "Black"],
    ["Latitude East", "Gray"],
    ["Southwester", "Dark Gray"],
    ["River Rock", "Dark Gray"],
    ["Spring Blossom", "Dark Gray"],
    ["Summer Breeze", "Almond"],
    ["Force of Nature", "Gray"],
    ["Rock Solid", "Gray"],
    ["Urban Vibe", "Gray"],
    ["First Class", "Black"],
    ["Fashionista", "Dark Brown"],
    ["Rhapsody", "Almond"],
    ["Sheer Beauty", "Almond"],
    ["The Chameleon", "Almond"],
    ["Free Spirit", "Gray"],
    ["After-Hours", "Dark Gray"],
    ["Apres-Ski", "Dark Brown"],
    ["First Light", "Almond"],
    ["Stargazer", "Dark Gray"],
    ["Sunday Brunch", "Bronze"],
    ["Sunset Cruise", "Dark Gray"],
    ["Tea for Two", "Dark Brown"],
    ["Tete-a-Tete", "Dark Gray"],
    ["Weekend Getaway", "Gray"],
    ["Winter Fun", "White"],
    ["Sugar on Ice", "Dark Brown"],
    ["Summertime Blues", "Gray"],
    ["Kiss Curl", "Dark Brown"],
    ["Natural Affinity", "Almond"],
    ["Mastermind", "Gray"],
    ["Sweet & Savory", "Dark Brown"],
    ["Love at First Sight", "Almond"],
    ["Acacia Honey", "Almond"],
    ["Sugar Rush", "Almond"],
    ["Salt of the Earth", "Dark Brown"],
  ]);

  return colorMap.get(woodColor) || woodColor;
}

function calculateExtraHardwareFor18Deep(
  width: string,
  quantity: number
): number {
  if (parseInt(width, 10) > 18) {
    return 6 * quantity;
  } else {
    return 4 * quantity;
  }
}

function calculateExtraHardwareForBackers(height: string, quantity: number): number {
  const parsedHeight = parseFloat(height);

  if (parsedHeight > 93.736) {
    return 18 * quantity;
  } else if (parsedHeight > 82.398) {
    return 16 * quantity;
  } else if (parsedHeight > 69.799) {
    return 14 * quantity;
  } else if (parsedHeight > 58.461) {
    return 12 * quantity;
  } else if (parsedHeight > 45.862) {
    return 10 * quantity;
  } else if (parsedHeight > 34.524) {
    return 8 * quantity;
  } else if (parsedHeight > 21.925) {
    return 6 * quantity;
  } else {
    return 4 * quantity;
  }
}

function setKickHeight(length: string, width: string): string {
  const lengthFloat = parseFloat(length);
  const widthFloat = parseFloat(width);
  let kickHeight = "None";
  if (lengthFloat > widthFloat) {
    if (widthFloat > 1 && widthFloat < 1.5) {
      kickHeight = "Extra Small";
    } else if (widthFloat >= 1.5 && widthFloat < 3.00) {
      kickHeight = "Small";
    } else if (widthFloat >= 3.00 && widthFloat < 4.5) {
      kickHeight = "Medium";
    } else if (widthFloat >= 4.5 && widthFloat < 5.5) {
      kickHeight = "Large";
    } else if (widthFloat >= 5.5 && widthFloat < 7.00) {
      kickHeight = "Extra Large";
    }
  } else if (widthFloat > lengthFloat) {
    if (lengthFloat > 1 && lengthFloat < 1.5) {
      kickHeight = "Extra Small";
    } else if (lengthFloat >= 1.5 && lengthFloat < 3.00) {
      kickHeight = "Small";
    } else if (lengthFloat >= 3.00 && lengthFloat < 4.5) {
      kickHeight = "Medium";
    } else if (lengthFloat >= 4.5 && lengthFloat < 5.5) {
      kickHeight = "Large";
    } else if (lengthFloat >= 5.5 && lengthFloat < 7.00) {
      kickHeight = "Extra Large";
    }
  } else {
    kickHeight = "Not Working";
  }
  return kickHeight;
}

function setKickWidth(length: string, width: string): string {
  let kickWidth: number;
  let lengthFloat = parseFloat(length);
  let widthFloat = parseFloat(width);
  if (lengthFloat > widthFloat) {
    kickWidth = lengthFloat;
  } else if (widthFloat > lengthFloat) {
    kickWidth = widthFloat;
  } else {
    kickWidth = 0;
  }
  return Number(kickWidth).toFixed(3);
}

function calculateHingeQtyOnDoorSize(length: string, quantity: number): number {
  const parsedLength = parseFloat(length);
  if (parsedLength > 76) {
    return 5 * quantity;
  } else if (parsedLength > 56) {
    return 4 * quantity;
  } else if (parsedLength > 36) {
    return 3 * quantity;
  } else if (parsedLength > 0) {
    return 2 * quantity;
  } else {
    return 0;
  }
}

function getDrawerFaceInfo(length: string): {
  drawerFaceHeight: string;
  simplifiedEnduraBoxType: string;
  simplifiedEnduraBoxHeight: string;
} {
  const lengthFloat = parseFloat(length);
  let drawerFaceHeight = "";
  let simplifiedEnduraBoxType = "";
  let simplifiedEnduraBoxHeight = "";
  if (lengthFloat > 11.5 && lengthFloat < 15) {
    drawerFaceHeight = "Extra Large";
    simplifiedEnduraBoxType = "Endura Large Box";
    simplifiedEnduraBoxHeight = "Large";
  } else if (lengthFloat > 10 && lengthFloat < 11.5) {
    drawerFaceHeight = "Large";
    simplifiedEnduraBoxType = "Endura Large Box";
    simplifiedEnduraBoxHeight = "Large";
  } else if (lengthFloat > 9 && lengthFloat < 10) {
    drawerFaceHeight = "Medium Large";
    simplifiedEnduraBoxType = "Endura Large Box";
    simplifiedEnduraBoxHeight = "Large";
  } else if (lengthFloat > 8 && lengthFloat < 9) {
    drawerFaceHeight = "Medium";
    simplifiedEnduraBoxType = "Endura Medium Box";
    simplifiedEnduraBoxHeight = "Medium";
  } else if (lengthFloat > 6.5 && lengthFloat < 8) {
    drawerFaceHeight = "Small Medium";
    simplifiedEnduraBoxType = "Endura Medium Box";
    simplifiedEnduraBoxHeight = "Medium";
  } else if (lengthFloat > 5.5 && lengthFloat < 6.5) {
    drawerFaceHeight = "Small";
    simplifiedEnduraBoxType = "Endura Small Box";
    simplifiedEnduraBoxHeight = "Small";
  } else if (lengthFloat > 4.5 && lengthFloat < 5.5) {
    drawerFaceHeight = "Extra Small";
    simplifiedEnduraBoxType = "Endura Small Box";
    simplifiedEnduraBoxHeight = "Small";
  } else {
    drawerFaceHeight = "Not Standard Drawer Face Height";
  }
  return {
    drawerFaceHeight,
    simplifiedEnduraBoxType,
    simplifiedEnduraBoxHeight,
  };
}

const drawerBoxHeightMap = new Map([
  ["11.48", "Extra Large"],
  ["10.22", "Large"],
  ["8.96", "Medium Large"],
  ["7.7", "Medium"],
  ["6.44", "Small Medium"],
  ["5.18", "Small"],
  ["3.92", "Extra Small"],
]);
function getDrawerBoxInfo(height: string = ""): string {
  const drawerBoxHeight = drawerBoxHeightMap.get(height.trim());
  return drawerBoxHeight || "Not Standard Drawer Box Height";
}

const enduraDrawerBoxHeightMap = new Map([
  ["11.48", "Large"],
  ["10.22", "Large"],
  ["8.96", "Medium"],
  ["7.7", "Medium"],
  ["6.44", "Medium"],
  ["5.18", "Small"],
  ["3.92", "Small"],
]);
function getEnduraDrawerBoxInfo(height: string = ""): string {
  const drawerBoxHeight = enduraDrawerBoxHeightMap.get(height.trim());
  return drawerBoxHeight || "Not Standard Drawer Box Height";
}

function calculateWoodDowelQty(height: string, quantity: number): number {
  const heightFloat = parseFloat(height);
  if (heightFloat > 9) {
    return 12 * quantity;
  }
  return 8 * quantity;
}


function getAttributesFromKCDProductName(
  name: string
): Record<string, string> | undefined {
  const attributeMatchers = [
    /^(?<height>.*?) (.*?)DBox$/,
    /^(.*?)(?<fabric>BEA|SLA)(.*?)/,
    /^(.*?)(?<finish>CR|MALCR|MAL|MNL|BLK|ORB|SG|MG)(.*?)/,
    /^(.*?)(?<leather>OYS|PWT|WIN)(.*?)/,
    /^(.*?) (?<colorlight>Cool|Warm) (.*?)/,
    /^(.*?)(?<filler> filler )(.*?)/,
    /^(.*?)(?<size>3in|9in|18.5in|24.5in|30.5in|36.5in|96mm|128mm|160mm|192mm|256mm|352mm|128MM|256MM)(.*?)/,
  ];

  const attributes = {}; // Initialize an empty object to store attributes

  for (const matcher of attributeMatchers) {
    const result = matcher.exec(name);
    if (result && result.groups) {
      // Merge captured groups into the attributes object
      Object.assign(attributes, result.groups);
    }
  }

  if (Object.keys(attributes).length > 0) {
    return attributes;
  } else {
    return undefined; // Return undefined if no attributes are found
  }
}


function getFabricName(fabricCode: string): string {
  const map = new Map([
    ["BEA", "Beach"],
    ["SLA", "Slate"],
  ]);
  return map.get(fabricCode) || fabricCode;
}

function getFinishName(finishCode: string): string {
  const map = new Map([
    ["CR", "Chrome"],
    ["MALCR", "Matte Aluminum & Chrome"],
    ["MAL", "Matte Aluminum"],
    ["MNL", "Matte Nickel"],
    ["ORB", "Oil Rubbed Bronze"],
    ["BLK", "Black"],
    ["MG", "Matte Gold"],
    ["SG", "Slate"],
  ]);
  return map.get(finishCode) || finishCode;
}

function getLeatherName(leatherCode: string): string {
  const map = new Map([
    ["OYS", "Oyster"],
    ["PWT", "Pewter"],
    ["WIN", "Winter"],
  ]);
  return map.get(leatherCode) || leatherCode;
}

function getSizeName(sizeCode: string): string {
  const map = new Map([
    ["3in", "3\""],
    ["9in", "9\""],
    ["18.5in", "18.5\""],
    ["24.5in", "24.5\""],
    ["30.5in", "30.5\""],
    ["36.5in", "36.5\""],
    ["96mm", "96mm"],
    ["128mm", "128mm"],
    ["160mm", "160mm"],
    ["192mm", "192mm"],
    ["256mm", "256mm"],
    ["352mm", "352mm"],
    ["128MM", "128mm"],
    ["256MM", "256mm"],
  ]);
  return map.get(sizeCode) || sizeCode;
}

function TAGHandleSizeTypeMap(TAGHandleSizeCode: string): string {
  const map = new Map([
    ["Elite Aluminum Pull 3in ", "None"],
    ["Elite Aluminum Pull 9in ", "None"],
    ["Elite Aluminum Pull 18.5in ", "None"],
    ["Elite Aluminum Pull 24.5in ", "None"],
    ["Elite Aluminum Pull 30.5in ", "None"],
    ["Elite Aluminum Pull 36.5in ", "None"],
    ["Elite Arched Handle ", "160mm"],
    ["Elite Bow Handle ", "128mm"],
    ["Elite Dished Knob ", "0mm"],
    ["Elite Dropped Handle ", "160mm"],
    ["Elite Dropped Modern Handle ", "128mm"],
    ["Elite Dropped Pull ", "16mm H"],
    ["Elite Handle 96mm ", "96mm"],
    ["Elite Handle 128mm ", "128mm"],
    ["Elite Handle 192mm ", "192mm"],
    ["Elite Modern Handle 128mm ", "128mm"],
    ["Elite Modern Handle 256mm ", "256mm"],
    ["Elite Modern Knob ", "16mm V"],
    ["Elite Round Knob ", "0mm"],
    ["Elite Square Knob ", "0mm"],
    ["Leather Pull 128MM ", "128mm"],
    ["Leather Pull 256MM ", "256mm"],
    ["Modern Leather Handle 128MM ", "128mm"],
    ["Modern Leather Handle 256MM ", "256mm"],
    ["Traditional Handle 128mm ", "128mm"],
    ["Traditional Handle 256mm ", "256mm"],
    ["Traditional Handle 352mm ", "352mm"],
    ["Traditional Knob ", "0mm"],
    ["Traditional Leather Handle 128mm ", "128mm"],
    ["Traditional Leather Handle 256mm ", "256mm"],
  ]);
  for (const [key, value] of map) {
    if (TAGHandleSizeCode.startsWith(key)) {
      return value;
    }
  }
  return TAGHandleSizeCode;
}

function getHandleType(
  roomName: string,
  wallNumber: string,
  unitNumber: string,
  allData: KCDRow[]
): string {
  const drawerFaces = allData.filter((row: KCDRow) => {
    const inRoom = row.roomName === roomName;
    const inWall = row.wallNumber === wallNumber;
    const inUnit = row.unitNumber === unitNumber;
    const isDrawerFace = row.kcdName.includes("DrFrt");
    return inRoom && inWall && inUnit && isDrawerFace;
  });

  if (drawerFaces.length === 0) {
    return "None";
  }

  // Calculate the counts of different handle type
  const handleTypes = drawerFaces.map((drawerRow: KCDRow) =>
    drawerRow.handleType
  );
  const handleTypeCounts = handleTypes.reduce(
    (acc: Record<string, number>, type: string) => {
      acc[type] = acc[type] ? acc[type] + 1 : 1;
      return acc;
    },
    {}
  );

  // Identify the most common handle size
  const mostCommonHandleType = Object.keys(handleTypeCounts).reduce(
    (a, b) => (handleTypeCounts[a] > handleTypeCounts[b] ? a : b)
  );

  return mostCommonHandleType;
}

function findStandardGableSize(target: number): number {
  const numberList: number[] = [
    0, 0.7479, 0.750, 1.000, 2.008, 3.2677, 4.5276, 5.7874, 7.047, 8.307, 9.567, 10.827,
    12.087, 13.346, 14.606, 15.866, 17.126, 18.386, 19.646, 20.906, 22.165, 23.425,
    24.685, 25.945, 27.205, 28.465, 29.724, 30.984, 32.244, 33.504, 34.764, 36.024,
    37.283, 38.543, 39.803, 41.063, 42.323, 43.583, 44.843, 46.102, 47.362, 48.622,
    49.882, 51.142, 52.402, 53.661, 54.921, 56.181, 57.441, 58.701, 59.961, 61.22,
    62.48, 63.74, 65, 66.26, 67.52, 68.78, 70.039, 71.299, 72.559, 73.819, 75.079,
    76.339, 77.598, 78.858, 80.118, 81.378, 82.638, 83.898, 85.157, 86.417, 87.677,
    88.937, 90.197, 91.457, 92.717, 93.976, 95.236
  ];

  let closestNumber: number = numberList[0]; // Assume first number is closest initially
  let smallestDifference: number = Math.abs(target - closestNumber);

  // Loop through each number in the list
  for (let number of numberList) {
    // Calculate the absolute difference between the target number and the current number
    let difference: number = Math.abs(target - number);

    // If the current difference is smaller than the smallest difference found so far,
    // update the closest number and smallest difference
    if (difference < smallestDifference) {
      closestNumber = number;
      smallestDifference = difference;
    }
  }

  return closestNumber;
}



export {
  removeSuffixFromColor,
  getGlideDrillingType,
  roundTo3Decimals,
  roundTo2Decimals,
  firstFiveChars,
  getHardwareColorFromWoodColor,
  calculateExtraHardwareFor18Deep,
  calculateExtraHardwareForBackers,
  setKickHeight,
  setKickWidth,
  calculateHingeQtyOnDoorSize,
  getDrawerFaceInfo,
  getDrawerBoxInfo,
  calculateWoodDowelQty,
  getAttributesFromKCDProductName,
  getFabricName,
  getFinishName,
  getLeatherName,
  getSimplifiedBoxType,
  getEnduraDrawerBoxInfo,
  getSizeName,
  TAGHandleSizeTypeMap,
  getHandleType,
  findStandardGableSize,
};
