/**
 * Returns the new name for the output CSV.
 *
 * @param {String} name
 * @returns New name for output CSV
 */
const getExportProductName = (name: string): string => {
  // prettier-ignore
  const nameMatchers = new Map([
    [/^(.*?)Mel Bttm - No Slide {5}DBox$/, "Melamine Box - Bottom Mount"],
    [/^(.*?)Veneer SC - No Slide {5}DBox$/, "Birch Veneer Box"],
    [/^(.*?)Mel Side - No Slide {5}DBox$/, "Melamine Box - Side Mount"],
    [/^(.*?)Mel Bttm w\/ Slide {5}DBox$/, "Melamine Box - Bottom Mount"],
    [/^(.*?)Veneer SC w\/ Slide {5}DBox$/, "Birch Veneer Box"],
    [/^(.*?)Mel Side w\/ Slide {5}DBox$/, "Melamine Box - Side Mount"],
    [/^11.48 File - No Slide {5}DBox$/, "File Drawer"],
    [/^11.48 File w\/ Slide {5}DBox$/, "File Drawer"],
    [/^5.18 File - No Slide {5}DBox$/, "Melamine Box - Side Mount"],
    [/^5.18 File w\/ Slide {5}DBox$/, "Melamine Box - Side Mount"],
    [/^(.*?)Endura Grey Soft Close {5}DBox$/, "Endura Box - Grey"],
    [/^(.*?)Endura Grey Soft Close W\/ Slide {5}DBox$/, "Endura Box - Grey"],
    [/^(.*?)Endura White Soft Close {5}DBox$/, "Endura Box - White"],
    [/^(.*?)Endura White Soft Close w\/ Slide {5}DBox$/, "Endura Box - White"],
    [/^(.*?)Shelf 45 Fixed$/, "Corner Shelf Pie - Fixed"],
    [/^(.*?)Shelf 45 Adjustable$/, "Corner Shelf Pie - Adjustable"],
    [/^(.*?)Shelf L-Fixed$/, "Corner Shelf L Shaped - Fixed"],
    [/^(.*?)Shelf L-Adjustable$/, "Corner Shelf L Shaped - Adjustable"],
    [/^(.*?)Shelf R-Fixed$/, "Corner Shelf Radius - Fixed"],
    [/^(.*?)Shelf R-Adjustable$/, "Corner Shelf Radius - Adjustable"],
    [/^(.*?)Gable Corner$/, "Corner Gable"],
    [/^(.*?)Curved Desk Gable$/, "Curved Desk Gable"],
    [/^(.*?)DrFrt - Euro Flat$/, "Euro Slab Drawer Face"],
    [/^(.*?)DrFrt - Euro 1mm$/, "Euro Slab Drawer Face"],
    [/^(.*?)DrFrt - Coastal Shaker$/, "Shaker Drawer Face"],
    [/^(.*?)DrFrt - Coastal Shaker Glass$/, "Shaker Drawer Face"],
    [/^(.*?)DrFrt - Terzetto$/, "Terzetto Drawer Face"],
    [/^(.*?)DrFrt - Terzetto TB$/, "Terzetto Drawer Face"],
    [/^(.*?)DrFrt - High Gloss$/, "Euro Slab Drawer Face"],
    [/^(.*?)Door - Euro Flat$/, "Euro Slab Door"],
    [/^(.*?)Door - Euro 1mm$/, "Euro Slab Door"],
    [/^(.*?)Door - Coastal Shaker$/, "Shaker Door"],
    [/^(.*?)Door - Coastal Shaker Glass$/, "Shaker Glass Door"],
    [/^(.*?)Door - Terzetto$/, "Terzetto Door"],
    [/^(.*?)Door - Terzetto TB$/, "Terzetto Door"],
    [/^(.*?)Door - High Gloss$/, "Euro Slab Door"],
    [/^(.*?)Oval Chrome Pole(.*?)/, "Oval Chrome Pole"],
    [/^Round Pole (.*?)/, "Round Pole"], /**We don't offer this. Can we get rid in KCD? */
    [/^Elite Round Pole (.*?)/, "Elite Pole"],
    [/^Signature Pole (.*?)/, "Signature Pole"],
    [/^Leather Wrapped Pole (.*?)/, "Leather Wrapped Pole"], /**We don't offer yet */
    [/^(.*?)Pant Rack (.*?)/, "Pull-out Pant Rack"],
    [/^18in Hamper 1 Lg Bag(.*?)/, "Pull-out Hamper"],
    [/^24in Hamper 2 Sm Bags(.*?)/, "Pull-out Hamper"],
    [/^(.*?) Elite Shoe Fence (.*?)/, "Elite Shoe Fence"],
    [/^Leather Cleatboard & End Caps (.*?)/, "Leather-Wrapped Cleat Board"], /**Include end caps */
    [/^Elite Cleat Mount Tie Hook (.*?)/, "Tie Hook"],
    [/^Elite Cleat Mount Belt Hook (.*?)/, "Belt Hook"],
    [/^Elite Cleat Mount Double Hook (.*?)/, "Double Hook"],
    [/^Elite Cleat Mount Coat Hook (.*?)/, "Coat Hook"],
    [/^Elite Cleat Mount Compact Coat Hook (.*?)/, "Compact Coat Hook"],
    [/^Elite Cleat Mount Waterfall Hook (.*?)/, "Waterfall Hook"],
    [/^Elite Cleat Mount Broom Holder (.*?)/, "Broom Hook"],
    [/^(.*?) Elite Tie Rack (.*?)/, "Elite Tie Rack"],
    [/^(.*?) Elite Belt Rack (.*?)/, "Elite Belt Rack"],
    [/^(.*?) Elite Scarf Rack (.*?)/, "Elite Scarf Rack"],
    [/^(.*?) Elite Valet Rack (.*?)/, "Elite Valet Rod"],
    [/^Elite Valet Pin (.*?)/, "Elite Valet Pin"],
    [/^35in Fixed Mirror (.*?)/, "Elite Fixed Mirror - 35\""],
    [/^47in Fixed Mirror (.*?)/, "Elite Fixed Mirror - 47\""],
    [/^(.*?) Pull Out Shelf No Dividers (.*?)/, "Engage Folding Station"], /**What is this? */
    [/^(.*?) Pull Out Shelf and Dividers (.*?)/, "Engage Divided Shelf"], /**Make Sure Correct */
    [/^(.*?) Folding Station (.*?)/, "Engage Folding Station"],
    [/^(.*?) Storage Box (.*?)/, "Engage Storage Box"],
    [/^(.*?) Jewelry Organizer (.*?)/, "Engage Jewelry Organizer"],
    [/^(.*?) Divided Deep Drawer (.*?)/, "Engage Divided Deep Drawer"],
    [/^(.*?) Divided Lingerie Drawer (.*?)/, "Engage Lingerie Drawer"],
    [/^(.*?) Shoe Organizer (.*?)/, "Engage Shoe Organizer"],
    [/^(.*?) Pant Organizer (.*?)/, "Engage Pant Organizer"],
    [/^(.*?) Laundry Organizer (.*?)/, "Engage Laundry Organizer"],
    [/^Metal Paper Tray (.*?)/, "Paper Tray"],
    [/^Metal Pencil Tray (.*?)/, "Pencil Tray"],
    [/^Metal Small Tray (.*?)/, "Small Tray"],
    [/^Metal Upright Tray (.*?)/, "Upright Tray"],
    [/^Metal File Hanger (.*?)/, "File Hanger"],
    [/^Track Wall Panel (.*?)/, "Track Panel"],
    [/^Tie Holder (.*?)/, "Tie Holder"],
    [/^Belt Holder (.*?)/, "Belt Holder"],
    [/^Double Hook (.*?)/, "Double Holder"],
    [/^Coat Hook (.*?)/, "Coat Holder"],
    [/^Bracelet Holder (.*?)/, "Bracelet Holder"],
    [/^Valet Hook (.*?)/, "Valet Hook"],
    [/^Small Storage Bin (.*?)/, "Small Storage Bins"],
    [/^Medium Storage Bin (.*?)/, "Medium Storage Bins"],
    [/^Large Storage Bin (.*?)/, "Large Storage Bins"],
    [/^LD Hand Tool Hook (.*?)/, "Hand Tool Hook"],
    [/^LD Paper Organizer Set (.*?)/, "Paper Organizer Set"],
    [/^LD Single Hook (.*?)/, "Single Hook"],
    [/^LD Bracket-Wire Basket (.*?)/, "Wire Basket Hooks"],
    [/^LD Screw Driver Holder (.*?)/, "Screwdriver Holder"],
    [/^LD Wrench Holder (.*?)/, "Wrench Holder"],
    [/^LD Pliers Holder (.*?)/, "Pliers Holder"],
    [/^HD 14in Bracket (.*?)/, "14\" Utility Hook"],
    [/^HD Shelf Bracket (.*?)/, "Shelf Bracket"],
    [/^HD Fishing Rod Holder (.*?)/, "Fishing Rod Holder"],
    [/^HD 16in Utility Hook (.*?)/, "16\" Utility Hook"],
    [/^HD Activity Rack (.*?)/, "Activity Rack"],
    [/^HD 5in Utility Hook (.*?)/, "5\" Utility Hook"],
    [/^HD Hose Hook (.*?)/, "Hose Hook"],
    [/^HD Bike Hook (.*?)/, "Bike Hook"],
    [/^HD Garden Tool Hook (.*?)/, "Garden Tool Hook"],
    [/^Symphony Shoe Hook (.*?)/, "Symphony Shoe Hook"],
    [/^Symphony Coat Hook (.*?)/, "Symphony Coat Hook"],
    [/^Symphony Triple Hook (.*?)/, "Symphony Triple Hook"],
    [/^Symphony Jewelry Hook (.*?)/, "Symphony Jewelry Hook"],
    [/^Symphony Mirror 14IN (.*?)/, "Elite Fixed Mirror - 14\""],
    [/^Symphony Leather Pouch (.*?)/, "Symphony Leather Pocket"],
    [/^1H (.*?) Symphony (.*?)/, "Symphony SOLO"],
    [/^7H (.*?) Symphony (.*?)/, "Symphony DUET"],
    [/^19H (.*?) Symphony (.*?)/, "Symphony QUARTET"],
    [/^42H (.*?) Symphony (.*?)/, "Symphony OCTAVE"],
    [/^66H (.*?) Symphony (.*?)/, "Symphony DODECA"],
    [/^Symphony Office Pencil Box WH (.*?)/, "Symphony Pencil Holder"],
    [/^Symphony Office Pencil Tray with Containers WH (.*?)/, "Symphony Container Tray"],
    [/^Symphony Office  Paper Tray WH (.*?)/, "Symphony Paper Tray"], //I don't like how this export item has an extra space
    [/^Symphony Office Tablet Stand with Whiteboard WH (.*?)/, "Symphony Tablet Stand"], /**Check on Whiteboard */
    [/^Symphony Office Triple Hook (.*?)/, "Symphony Triple Hook"],
    [/^Symphony Office Coat Hook (.*?)/, "Symphony Coat Hook"],
    [/^Symphony Office Shoe Hook (.*?)/, "Symphony Shoe Hook"],
    [/^Symphony Office Jewelry Hook (.*?)/, "Symphony Jewelry Hook"],
    [/^Symphony Office Leather Pouch (.*?)/, "Symphony Leather Pocket"],
    [/^Symphony Office Mirror 35IN (.*?)/, "Elite Fixed Mirror - 35\""], /**Make sure to include mirror clips */
    [/^7H (.*?) Symphony Office (.*?)/, "Symphony DUET"], //I don't like how this export item has an extra space
    [/^19H (.*?) Symphony Office (.*?)/, "Symphony QUARTET"], /**Check what Office Means. Maybe Add Extensions */ 
    [/^42H (.*?) Symphony Office (.*?)/, "Symphony OCTAVE"],
    [/^66H (.*?) Symphony Office (.*?)/, "Symphony DODECA"],
    [/^12H (.*?) Symphony Surface (.*?)/, "Symphony DUET Surface Mount"],
    [/^(.*?) 14D Illuminated Glass Shelf (.*?)/, "Illuminated Glass Shelf - 14\" Deep"],
    [/^(.*?) 205D Illuminated Glass Shelf (.*?)/, "Illuminated Glass Shelf - 21\" Deep"],
    [/^Elite Aluminum Pull (.*?)/, "Elite Classic Aluminum Pull"], //New Handles from here down, check to make sure works
    [/^Elite Arched Handle (.*?)/, "Elite Transitional Arched Handle"],
    [/^Elite Bow Handle (.*?)/, "Elite Transitional Bow Handle"],
    [/^Elite Dished Knob (.*?)/, "Elite Transitional Dished Knob"],
    [/^Elite Dropped Handle (.*?)/, "Elite Transitional Dropped Handle"],
    [/^Elite Dropped Modern Handle (.*?)/, "Elite Modern Dropped Handle"],
    [/^Elite Dropped Pull (.*?)/, "Elite Transitional Dropped Pull"],
    [/^Elite Handle (.*?)/, "Elite Classic Handle"],
    [/^Elite Modern Handle (.*?)/, "Elite Modern Handle"],
    [/^Elite Modern Knob (.*?)/, "Elite Modern Knob"],
    [/^Elite Round Knob (.*?)/, "Elite Classic Round Knob"],
    [/^Elite Square Knob (.*?)/, "Elite Classic Square Knob"],
    [/^Leather Pull (.*?)/, "Leather Wrapped Pull"], /**We don't offer yet */
    [/^Modern Leather Handle (.*?)/, "Leather Wrapped Modern Handle"], /**We don't offer yet */
    [/^Traditional Handle (.*?)/, "Elite Traditional Round Handle"],
    [/^Traditional Knob (.*?)/, "Elite Traditional Round Knob"],
    [/^Traditional Leather Handle (.*?)/, "Leather Wrapped Traditional Handle"], /**We don't offer yet */
  ]);
  for (const [key, value] of nameMatchers.entries()) {
    if (key.test(name)) {
      return value;
    }
  }
  return name;
};

export default getExportProductName;
